import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import { WithChildren } from 'common/types';
import { ButtonSchema, ButtonSize } from './constants';

import styles from './styles.module.scss';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  schema: ButtonSchema,
  size: ButtonSize,
  label?: string,
}

const Button = ({
  schema = ButtonSchema.primary,
  size = ButtonSize.standard,
  label,
  children,
  className,
  ...props
}: WithChildren<IButtonProps>) => ( // to change children to the React.Node
  <button
    type="button"
    {...props}
    className={classNames(styles.button, className, {
      [styles[schema]]: !!schema,
      [styles[size]]: !!size,
      [styles.disabled]: props.disabled,
    })}
  >
    {label || children}
  </button>
);

export default Button;
