import type { IServerFieldMultiSelectState, IServerFieldSchema } from 'common/types/fields';
import { FieldsType, IServerFieldStateSelected, UnitedFieldState } from 'common/types/fields';
import type { IFieldsUpdate, IWholeSchemaWithState } from 'containers/GenericFieldsHandlingLayer/types';

import { WHOLE_SCHEMA_DATA } from 'containers/FakeDataService/fakeData';
import { generateFieldOptions } from './utils';
import { independentFields } from './constants';


interface IDataService {
  updateState: (updateObj: IFieldsUpdate) => Array<UnitedFieldState>,
  wholeSchema?: IWholeSchemaWithState,
}

/**
 * @deprecated
 */
export class FakeDataService implements IDataService {
  // eslint-disable-next-line no-use-before-define
  private static singletone: FakeDataService;

  wholeSchema: IWholeSchemaWithState;

  private constructor(schema?: IWholeSchemaWithState) {
    this.wholeSchema = schema ?? WHOLE_SCHEMA_DATA;
  }

  public static getInstance(schema?: IWholeSchemaWithState) {
    if (!FakeDataService.singletone) {
      FakeDataService.singletone = new FakeDataService(schema);
    }
    return FakeDataService.singletone;
  }

  // eslint-disable-next-line class-methods-use-this
  public decodeFieldValue(
    value: string | number | string[] | undefined,
    fieldSchema: IServerFieldSchema,
    state: UnitedFieldState,
  ): IServerFieldStateSelected | undefined {
    if (typeof value === 'undefined') {
      return undefined;
    }
    const isArray = Array.isArray(value);
    const arr = isArray ? value : [value];

    switch (state.type) {
      case FieldsType.input:
        if (!isArray) {
          return {
            value,
          };
        }
        break;
      case FieldsType.checkboxes:
      case FieldsType.radioGroup:
      case FieldsType.dropdown:
        if (state.value?.options) {
          return arr.map((id) => state.value?.options?.find((opt) => opt.id === id)).map((opt) => ({
            ...((opt!.attributes) ? { attributes: opt!.attributes } : {}),
            id: opt!.id,
            title: opt!.title,
          }));
        }
        break;
      default:
        if (!isArray) {
          return {
            value,
          };
        }
    }
    return undefined;
  }

  public updateState(updateObj: IFieldsUpdate) {
    const fields = Object.entries(updateObj.data).map(([fieldId, value]) => ({ fieldId, value }));
    if (fields.length) {
      // we are interested in only first element, because all next fields and options are dependent on first changed field
      const updatedField = fields[0];
      const stateFieldIndex = this.wholeSchema.state.findIndex((currentField) => currentField.name === updatedField.fieldId);
      const schemaFieldIndex = this.wholeSchema.schema.findIndex((el) => el.name === updatedField.fieldId);
      const updatedFieldSchema = this.wholeSchema.schema[schemaFieldIndex];
      const updatedFieldState = this.wholeSchema.state[stateFieldIndex];
      const fieldValue = this.decodeFieldValue(updatedField.value, updatedFieldSchema, updatedFieldState);
      if (this.wholeSchema.state[stateFieldIndex]) {
        if (independentFields.includes(this.wholeSchema.state[stateFieldIndex].name)) {
          this.wholeSchema.state[stateFieldIndex].value!.selected = fieldValue;
          const updatedIndependentFields = this.wholeSchema.state[stateFieldIndex];
          return [updatedIndependentFields];
        }
        this.wholeSchema.state[stateFieldIndex].value!.selected = fieldValue;
        for (let i = stateFieldIndex + 1; i < this.wholeSchema.state.length; i++) {
          if (!independentFields.includes(this.wholeSchema.state[i].name)) {
            (this.wholeSchema.state[i] as IServerFieldMultiSelectState).value!.options = undefined;
            this.wholeSchema.state[i].value!.selected = undefined;
            this.wholeSchema.state[i].status = 'disabled';
          }
        }
        if (stateFieldIndex === this.wholeSchema.state.length - 1) {
          if (!independentFields.includes(this.wholeSchema.schema[schemaFieldIndex + 1].name)) {
            this.wholeSchema.state.push({
              type: this.wholeSchema.schema[schemaFieldIndex + 1].type,
              name: this.wholeSchema.schema[schemaFieldIndex + 1].name,
              ...(this.wholeSchema.schema[schemaFieldIndex + 1].type !== FieldsType.input
                ? {
                  options: generateFieldOptions(
                    this.wholeSchema.schema[schemaFieldIndex + 1].name,
                    this.wholeSchema.schema[schemaFieldIndex + 1].title,
                  ),
                }
                : {}
              ),
              status: 'incomplete',
            });
          }
        } else if (!independentFields.includes(this.wholeSchema.state[stateFieldIndex + 1].name)) {
          (this.wholeSchema.state[stateFieldIndex + 1] as IServerFieldMultiSelectState).value!.options = generateFieldOptions(
            this.wholeSchema.schema[schemaFieldIndex + 1].name,
            this.wholeSchema.schema[schemaFieldIndex + 1].title,
          );
          this.wholeSchema.state[stateFieldIndex + 1].status = 'incomplete';
        }
        return this.wholeSchema.state.slice(stateFieldIndex);
      }
    }
    return [];
  }
}
