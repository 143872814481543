import { useCallback, useEffect, useRef } from 'react';

type CommonFuncPropsType = Array<any>;
type CommonFuncType = (...args: CommonFuncPropsType) => void;

const customDebounce = (callback: CommonFuncType, delay: number) => {
  // eslint-disable-next-line no-undef
  let debounceTO: NodeJS.Timeout | null = null;
  return (...args: CommonFuncPropsType) => {
    if (debounceTO) {
      clearTimeout(debounceTO);
    }
    debounceTO = setTimeout(() => callback(...args), delay);
  };
};

export const useDebounceCallback = (callback: CommonFuncType | undefined, delay: number = 250) => {
  const callbackRef = useRef<CommonFuncType | undefined>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useCallback(
    customDebounce((...args: CommonFuncPropsType) => {
      callbackRef.current && callbackRef.current(...args);
    }, delay),
    [delay],
  );
};
