import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { FieldsType, ProjectFields } from 'common/types/fields';
import { LengthType } from 'common/types/additional';
import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { useActions, useLengthTypeData } from 'containers/AdditionalDataStorage/context';
import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import Switch from 'common/components/Switch';
import Loader from 'common/components/Loader';

import overallAssemblyLength1 from 'assets/overall-assembly-length-1.svg';
import overallAssemblyLength2 from 'assets/overall-assembly-length-2.svg';
import overallAssemblyLength3 from 'assets/overall-assembly-length-3.svg';

import { toFixedByLengthType, useTranslateAssemblyLengthToAppropriateLengthType } from './utils';
import styles from './styles.module.scss';
import { isFieldDisabled } from '../../utils/isStatusDisabled';


const AssemblyLengthView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  children,
  clearProgressError,
  handleSetNoFieldsAvailable,
}: WithChildren<IViewProps>) => {
  const idForm = 'form-assembly-length';

  const { error, isLoading, order } = useGenericFieldsDataAdopted();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted();
  const { setData } = useActions();
  const lengthType = useLengthTypeData();

  const fields = useMemo(
    () => getFieldsByName(fieldsNames),
    [getFieldsByName, fieldsNames],
  );

  const assemblyLengthField = useTranslateAssemblyLengthToAppropriateLengthType(fields, lengthType);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
    clearErrors,
  } = useForm<IFormValues>({ defaultValues });

  //  We sure that this view have to contain this field and for data transformation while changing length type
  //  we need to set type of this field
  //  But if field type will be changed on the Rules-engine side - there are will be a problem
  const assemblyLengthWatched = watch(ProjectFields.assemblyLength) as number;

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const handleFieldUpdate = useCallback((fieldType: FieldsType, fieldName: ProjectFields, changedValue?: IFormValues[keyof IFormValues]) => {
    if (clearProgressError) { clearProgressError(); }
    if (fieldName === ProjectFields.assemblyLength) {
      updateFieldsState(
        fieldType,
        fieldName,
        changedValue && toFixedByLengthType(Number(changedValue), lengthType, LengthType.inches),
      );
    } else {
      updateFieldsState(fieldType, fieldName, changedValue);
    }
  }, [clearProgressError, updateFieldsState, lengthType]);

  const onLengthTypeChange = useCallback(
    (lType: LengthType) => {
      setValue(
        ProjectFields.assemblyLength,
        toFixedByLengthType(
          Number(assemblyLengthWatched),
          lType,
          lType === LengthType.inches ? LengthType.feet : LengthType.inches,
        ),
      );
      clearErrors(ProjectFields.assemblyLength);
      setData('lengthType', lType);
    },
    [setValue, assemblyLengthWatched, clearErrors, setData],
  );

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  useEffect(() => {
    if (assemblyLengthField?.name) {
      setFocus(assemblyLengthField.name, { shouldSelect: true });
    }
  }, [assemblyLengthField?.name]);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {isLoading && (
          <div className={styles.loaderBlock}>
            <Loader />
          </div>
        )}
        <div className={styles.assemblyLength}>
          {order && order.map((fieldName) => {
            if (fieldsNames.includes(fieldName)) {
              if (fieldName === ProjectFields.assemblyLength && assemblyLengthField) {
                return (
                  <React.Fragment key={assemblyLengthField.name}>
                    <FieldGenerator
                      className={classNames(styles.length)}
                      field={assemblyLengthField}
                      name={assemblyLengthField.name as string}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      getFieldState={getFieldState}
                      onChangeHandler={handleFieldUpdate}
                      isDisabled={isFieldDisabled(assemblyLengthField)}
                      resetField={resetField}
                    />
                    <Switch<LengthType>
                      className={styles.switchWrapper}
                      label1={LengthType.inches}
                      label2={LengthType.feet}
                      defaultVal={lengthType}
                      onChange={onLengthTypeChange}
                    />
                  </React.Fragment>
                );
              }
              return (
                <FieldGenerator
                  className={classNames(styles.length)}
                  field={fields[fieldName]!}
                  name={fields[fieldName]?.name as string}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  getFieldState={getFieldState}
                  key={fields[fieldName]?.name}
                  onChangeHandler={handleFieldUpdate}
                  isDisabled={isFieldDisabled(fields[fieldName])}
                  resetField={resetField}
                />
              );
            }
            return null;
          })}
        </div>
        <div className={styles.images}>
          <div
            className={styles.image}
            key="Type1"
          >
            <img
              src={overallAssemblyLength1}
              alt="Example Overall Assembly Length"
            />
          </div>
          <div
            className={styles.image}
            key="Type2"
          >
            <img
              src={overallAssemblyLength2}
              alt="Example Overall Assembly Length"
            />
          </div>
          <div
            className={styles.image}
            key="Type3"
          >
            <img
              src={overallAssemblyLength3}
              alt="Example Overall Assembly Length"
            />
          </div>
        </div>
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        onBackClickHandler={onBackClickHandler}
      />
    </FormWrapper>
  );
};

export default AssemblyLengthView;
