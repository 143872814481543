import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import classNames from 'classnames';
import TopFittingSvg from 'assets/top-fitting.svg';
import BottomFittingSvg from 'assets/bottom-fitting.svg';

import { MAX_ROTATION_ANGLE } from './constants';

import styles from './styles.module.scss';

gsap.registerPlugin(Draggable);

interface IRotationProps {
  rotationSnap: number,
  rotationValue: number,
  onChangeRotationValue: (angle: number) => void,
  className?: string
}

const Rotation = ({
  rotationSnap,
  rotationValue,
  onChangeRotationValue,
  className,
}: IRotationProps) => {
  const bottomFitting = useRef<HTMLDivElement>(null);
  const angleRef = useRef<number>(rotationValue);
  const [angle, setAngle] = useState(rotationValue);

  useEffect(() => {
    setAngle(rotationValue);
    gsap.set(bottomFitting.current, { rotate: rotationValue });
  }, [rotationValue]);

  useEffect(() => {
    gsap.set(bottomFitting.current, { transition: 'transform .3s ease-out' });

    Draggable.create(bottomFitting.current, {
      type: 'rotation',
      bounds: { maxRotation: MAX_ROTATION_ANGLE, minRotation: 0 },
      liveSnap(endValue) {
        let snappedAngle = Math.round(endValue / rotationSnap) * rotationSnap;
        if (snappedAngle === 360) {
          snappedAngle = 0;
        }
        setAngle(snappedAngle);
        angleRef.current = snappedAngle;
        return snappedAngle;
      },
      onDragEnd() {
        onChangeRotationValue(angleRef.current);
      },
    });
  }, [rotationSnap, onChangeRotationValue]);

  const strokeDasharray = useMemo(() => 2 * 3.14 * 100, []);

  const strokeDashoffset = useMemo(() => strokeDasharray * ((100 - ((angle / 360) * 100)) / 100), [strokeDasharray, angle]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.rotation}>
        <span className={classNames(styles.measurement, styles.measurement0)}>
          0
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurementSm, styles.measurement45)}>
          45
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurement90)}>
          90
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurementSm, styles.measurement135)}>
          135
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurement180)}>
          180
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurementSm, styles.measurement225)}>
          225
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurement270)}>
          270
          <span>&#176;</span>
        </span>
        <span className={classNames(styles.measurement, styles.measurementSm, styles.measurement315)}>
          315
          <span>&#176;</span>
        </span>
        <svg className={styles.indicator}>
          <circle
            cx={114}
            cy={114}
            r={100}
            className={styles.indicatorTrack}
          />
          <circle
            cx={114}
            cy={114}
            r={100}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            className={styles.indicatorProgress}
          />
        </svg>
        <div className={styles.topFitting}>
          <img src={TopFittingSvg} alt="Fitting 1" />
        </div>
        <div
          className={styles.bottomFitting}
          ref={bottomFitting}
        >
          <img src={BottomFittingSvg} alt="Fitting 2" />
          <div className={styles.cursor} />
        </div>
      </div>
    </div>
  );
};

export default Rotation;
