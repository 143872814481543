import { IServerItemOption, ProjectFields } from 'common/types/fields';
import { DEFAULT_ROTATION_ANGLE_STEP } from 'views/AngleRotationView/constants';
import { FITTING_IMGS, STATE_HOSE, TEST_FIELD_RADIO } from './fakeData';


export const generateDropdownDataByFieldName = (fieldTitle: string, imgSource?: Array<string>): Array<IServerItemOption> => {
  const temp = Array.from(
    { length: Math.round(Math.random() * 10) + 2 },
    (el, index) => index,
  );
  return temp.map((el) => ({
    id: `${Math.random() * 10000}_${fieldTitle}`,
    title: `Test ${fieldTitle} ${el}`,
    ...(imgSource
      ? {
        attributes: {
          image: imgSource[Math.round(Math.random() * imgSource.length - 1)],
        },
      }
      : {}
    ),
  }));
};

export const generateFieldOptions = (fieldName: ProjectFields, fieldTitle?: string) => {
  switch (fieldName) {
    case ProjectFields.hoseProduct: {
      return STATE_HOSE.value.options;
    }
    case ProjectFields.fitting1Product:
    case ProjectFields.fitting2Product: {
      return generateDropdownDataByFieldName(fieldTitle || fieldName, FITTING_IMGS);
    }
    case ProjectFields.angleOfRotation: {
      const numberArray = [];
      for (let i = 0; i < 360; i += DEFAULT_ROTATION_ANGLE_STEP) {
        numberArray.push(i);
      }
      return numberArray.map((number) => ({ id: String(number), title: String(number) }));
    }
    case ProjectFields.accessories: {
      return TEST_FIELD_RADIO.value?.options;
    }
    default:
      return generateDropdownDataByFieldName(fieldTitle || fieldName);
  }
};
