import { DEFAULT_UNKNOWN_ERROR_MESSAGE, DEFAULT_UNKNOWN_ERROR_STATUS } from 'api/constants';
import { isAxiosError } from './isAxiosError';


export const makeDefaultError = (error: any) => ({
  status: DEFAULT_UNKNOWN_ERROR_STATUS,
  statusText: DEFAULT_UNKNOWN_ERROR_MESSAGE,
  rawError: error,
  isAxiosError: isAxiosError(error),
});
