import React, { useContext } from 'react';
import { IAdditionalSataStorageContext } from './types';


export const AdditionalDataStorageContext = React.createContext<IAdditionalSataStorageContext | undefined>(undefined);

/**
 * @name useAdditionalDataStorage - hook to take a AdditionalDataStorageContext. Store Assembly length type, required components and any additional data
 * @type IAdditionalSataStorageContext | undefined
 */
export const useAdditionalDataStorage = () => {
  const context = useContext(AdditionalDataStorageContext);

  if (!context) {
    throw new Error('No AdditionalDataStorageContext found. This hook have to be used only inside appropriate context provider');
  }
  return context;
};

/**
 * @name useData - hook to take Assembly length type, required components and any additional data
 * @type IAdditionalSataStorageContext[data]
 */
export const useData = () => {
  const context = useAdditionalDataStorage();

  if (!context) {
    throw new Error('No AdditionalDataStorageContext found. This hook have to be used only inside appropriate context provider');
  }
  return context.data;
};

/**
 * @name useLengthTypeData - hook to take Assembly length type
 * @type IAdditionalSataStorageContext[data][lengthType]
 */
export const useLengthTypeData = () => {
  const data = useData();

  if (!data.lengthType) {
    throw new Error('No lengthType found. lengthType property should have default value. Please, check appropriate context provider');
  }
  return data.lengthType;
};

/**
 * @name useRequiredComponentData - hook to take required components
 * @type IAdditionalSataStorageContext[data][requiredComponents]
 */
export const useRequiredComponentData = () => {
  const data = useData();

  return data.requiredComponents;
};

/**
 * @name useActiveSelectedConfiguration - hook to active selected configuration in secondary nav
 * @type IAdditionalSataStorageContext[data][activeSelectedConfiguration]
 */
export const useActiveSelectedConfiguration = () => {
  const data = useData();

  return data.activeSelectedConfiguration;
};

/**
 * @name useConfigurationQuantity - hook to quantity of the prepared configuration from last view
 * @type IAdditionalSataStorageContext[data][configQuantity]
 */
export const useConfigurationQuantity = () => {
  const data = useData();

  return data.configQuantity;
};

/**
 * @name useActions - hook to take CRUD data actions
 * @type IAdditionalSataStorageContext['actions']
 */
export const useActions = () => {
  const context = useAdditionalDataStorage();

  if (!context) {
    throw new Error('No AdditionalDataStorageContext found. This hook have to be used only inside appropriate context provider');
  }
  return context.actions;
};
