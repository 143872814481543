import React from 'react';

export const Item = ({
  title, onClick, image, label, className,
}:
                         {
                             title?: string | React.ReactNode,
                             className?: string,
                             onClick: () => void,
                             image?: string,
                             label: string
                         }) => (
                           <div className={`item ${className}`}>
                             {/* eslint-disable-next-line */}
        <a onClick={onClick}>{label}</a>
                             <div>
                               {image && <img src={image} alt="" />}
                               <div>{title}</div>
                             </div>
                           </div>
);
