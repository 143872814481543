import React, { useCallback } from 'react';

import { useActions, useConfigurationQuantity } from 'containers/AdditionalDataStorage/context';
import Tooltip from 'common/components/Tooltip';
import Quantity from 'common/components/Quantity';

import styles from './styles.module.scss';


interface IProps {
  className?: string,
}

export const MIN_QUANTITY = 1;
export const MAX_QUANTITY = 999;

export const QuantityConnected = ({ className }: IProps) => {
  const { setData } = useActions();
  const configQuantity = useConfigurationQuantity();

  const handleSetCounter = useCallback((count: number) => {
    setData('configQuantity', Math.max(Math.min(count, MAX_QUANTITY), MIN_QUANTITY));
  }, [setData]);

  return (
    <>
      <div className={styles.quantityLabel}>
        Assembly Qty:
        <Tooltip
          className={styles.iconTooltip}
          content="Please enter the required quantity for this assembly."
        />
      </div>
      <Quantity
        className={className}
        count={configQuantity}
        handleSetCounter={handleSetCounter}
      />
    </>
  );
};
