import React from 'react';
import classNames from 'classnames';

import { ButtonSchema, ButtonSize } from 'common/components/Button/constants';
import Button from 'common/components/Button';

import styles from './styles.module.scss';


interface IProps {
  nextButtonText?: string,
  backButtonText?: string,
  idForm: string,
  isNextDisabled?: boolean,
  isBackHidden?: boolean,
  onNextClickHandler?: () => void,
  onBackClickHandler?: () => void,
  onResetClickHandler?: () => void,
  className?: string,
}

const ViewsButtonsBlock = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetClickHandler,
  nextButtonText = 'Next',
  backButtonText = 'Back',
  idForm,
  isBackHidden,
  isNextDisabled,
  className,
}: IProps) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.backAndReset}>
      {!isBackHidden && (
        <Button
          schema={ButtonSchema.secondary}
          size={ButtonSize.standard}
          onClick={onBackClickHandler}
          className={styles.button}
        >
          {backButtonText}
        </Button>
      )}
      <Button
        schema={ButtonSchema.transparent}
        size={ButtonSize.standard}
        onClick={onResetClickHandler}
        className={styles.buttonReset}
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* eslint-disable-next-line max-len */}
          <path d="M12.3011 4.03924C12.6909 3.79454 12.8085 3.28019 12.5638 2.89039C12.3191 2.50059 11.8048 2.38296 11.415 2.62765L8.89302 4.2108C8.70584 4.3283 8.57299 4.51536 8.52372 4.73081C8.47445 4.94626 8.51278 5.17246 8.63029 5.35965L10.2134 7.88161C10.4581 8.27141 10.9725 8.38904 11.3623 8.14435C11.7521 7.89966 11.8697 7.3853 11.625 6.9955L10.9934 5.98938C13.0473 6.52592 14.563 8.39391 14.563 10.6159C14.563 13.2566 12.4222 15.3974 9.7815 15.3974C7.14077 15.3974 5.00004 13.2566 5.00004 10.6159C5.00004 9.24842 5.57303 8.01611 6.49435 7.14357C6.82851 6.8271 6.84286 6.29966 6.52639 5.9655C6.20992 5.63133 5.68248 5.61699 5.34831 5.93346C4.10848 7.10764 3.33337 8.77194 3.33337 10.6159C3.33337 14.1771 6.2203 17.064 9.7815 17.064C13.3427 17.064 16.2296 14.1771 16.2296 10.6159C16.2296 7.70655 14.3028 5.24723 11.6557 4.44438L12.3011 4.03924Z" fill="#0075BF" />
        </svg>
        <span>Reset Values</span>
      </Button>
    </div>
    <Button
      form={idForm}
      schema={ButtonSchema.primary}
      size={ButtonSize.standard}
      onClick={onNextClickHandler}
      className={styles.button}
      disabled={isNextDisabled}
      type="submit"
      data-submit-button
    >
      {nextButtonText}
    </Button>
  </div>
);

export default ViewsButtonsBlock;
