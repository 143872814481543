import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { FieldsType, ProjectFields } from 'common/types/fields';
import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { FormKeyType, IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import { FormLoader } from 'common/components/Loader';
import Tooltip from 'common/components/Tooltip';

import AccessoriesRadioButtonContainer from './components/AccessoriesRadioButtonContainer';

import styles from './styles.module.scss';
import { isFieldDisabled } from '../../utils/isStatusDisabled';
import { useIsNextStepAvailable } from '../../hooks/useIsNextStepAvailable';

const AccessoriesSelectorView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  handleSetNoFieldsAvailable,
  fieldsNames,
  children,
  clearProgressError,
  isNextDisabled,
  isLoading: isCartLoading,
}: WithChildren<IViewProps>) => {
  const idForm = 'form-accessories';
  const { error, isLoading, order } = useGenericFieldsDataAdopted();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted();

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
  } = useForm<IFormValues>({ defaultValues });

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: ProjectFields,
    changedValue?: IFormValues[keyof IFormValues],
  ) => {
    if (clearProgressError) {
      clearProgressError();
    }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  const renderAccessoriesRadioButton = useCallback(() => AccessoriesRadioButtonContainer, []);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  const accessoriesField = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ProjectFields.accessories) && fields[ProjectFields.accessories] && (
      <FieldGenerator<FormKeyType>
        className={styles.accessories}
        field={fields[ProjectFields.accessories]!}
        name={fields[ProjectFields.accessories]!.name}
        control={control}
        watch={watch}
        setValue={setValue}
        getFieldState={getFieldState}
        key={fields[ProjectFields.accessories]!.name}
        onChangeHandler={handleFieldUpdate}
        isDisabled={isFieldDisabled(fields[ProjectFields.accessories])}
        resetField={resetField}
        renderOptionLabelComponent={renderAccessoriesRadioButton}
      />
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, renderAccessoriesRadioButton, resetField, setValue, watch]);

  const testingField = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ProjectFields.testingConfiguration) && fields[ProjectFields.testingConfiguration] && (
      <div className={styles.testing}>
        <FieldGenerator<FormKeyType>
          className={styles.checkbox}
          field={fields[ProjectFields.testingConfiguration]!}
          name={fields[ProjectFields.testingConfiguration]!.name}
          control={control}
          watch={watch}
          setValue={setValue}
          getFieldState={getFieldState}
          key={fields[ProjectFields.testingConfiguration]!.name}
          onChangeHandler={handleFieldUpdate}
          isDisabled={isFieldDisabled(fields[ProjectFields.testingConfiguration])}
          resetField={resetField}
        />
      </div>
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, resetField, setValue, watch]);

  const customerField = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ProjectFields.customerSerialNumber) && fields[ProjectFields.customerSerialNumber] && (
      <div className={styles.partNumber}>
        <div className={styles.partNumberLabel}>
          Customer Part Number
          <Tooltip
            className={styles.iconTooltip}
            content="Optional field to enter a custom part number."
          />
        </div>
        <FieldGenerator<FormKeyType>
          className={styles.input}
          field={fields[ProjectFields.customerSerialNumber]!}
          name={fields[ProjectFields.customerSerialNumber]!.name}
          control={control}
          watch={watch}
          setValue={setValue}
          getFieldState={getFieldState}
          key={fields[ProjectFields.customerSerialNumber]!.name}
          onChangeHandler={handleFieldUpdate}
          isDisabled={isFieldDisabled(fields[ProjectFields.customerSerialNumber])}
          resetField={resetField}
        />
      </div>
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, resetField, setValue, watch]);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {(isLoading || isCartLoading) && <FormLoader />}
        {accessoriesField}
        <div className={styles.box}>
          {testingField}
          {customerField}
        </div>
        {order && order.map((fieldName) => {
          if (fieldsNames.includes(fieldName) && !!fields[fieldName]) {
            switch (fieldName) {
              case ProjectFields.accessories:
              case ProjectFields.testingConfiguration:
              case ProjectFields.customerSerialNumber:
                return null;
              default: {
                return (
                  <FieldGenerator<FormKeyType>
                    className={styles.input}
                    field={fields[fieldName]!}
                    name={fields[fieldName]!.name}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getFieldState={getFieldState}
                    key={fields[fieldName]!.name}
                    onChangeHandler={handleFieldUpdate}
                    isDisabled={isFieldDisabled(fields[fieldName])}
                    resetField={resetField}
                  />
                );
              }
            }
          }
          return null;
        })}
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        onBackClickHandler={onBackClickHandler}
        isNextDisabled={isNextDisabled}
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default AccessoriesSelectorView;
