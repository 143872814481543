import { IAxiosResponseError } from 'api/types';
import { isAxiosError } from './isAxiosError';


export const determineErrorType = <ExpectedDataFormat extends unknown>(
  error: IAxiosResponseError<ExpectedDataFormat> | unknown,
): IAxiosResponseError<ExpectedDataFormat> | Error => {
  if (isAxiosError(error)) {
    return error as unknown as IAxiosResponseError<ExpectedDataFormat>;
  }
  return error as unknown as Error;
};
