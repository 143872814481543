import React, { useContext } from 'react';

import type { IAxiosResponseError } from 'api/types';
import {
  CheckboxFieldStateType,
  RadioFieldStateType,
  DropdownFieldStateType,
  InputFieldStateType,
  ISelectedFieldData,
  ProjectFields,
} from 'common/types/fields';
import { IStorage } from './types';


export interface IGenericFieldsHandlingLayer<FieldKeys extends PropertyKey, Field, FieldsChangeset extends Record<string, any>> {
  data: IStorage<FieldKeys, Field, IAxiosResponseError<unknown>>,
  session: {
    sessionId?: string,
  }
  actions: {
    updateFieldsState: (updatedFields: Array<FieldsChangeset>) => void,
    getFieldsByName: (fieldsNames: Array<FieldKeys>) => {[key in FieldKeys]? : Field },
  },
}
export type PredefinedContextType = IGenericFieldsHandlingLayer<
  ProjectFields,
  InputFieldStateType | DropdownFieldStateType | CheckboxFieldStateType | RadioFieldStateType,
  ISelectedFieldData
>;

export const FieldsDataHandlerContext = React.createContext<
  PredefinedContextType
  | undefined
>(undefined);

/**
 * @name useGFHLContext - hook to take a FieldsDataHandlerContext. Store schema, state data, actions to update data
 * @type IGenericFieldsHandlingLayer | undefined
 */
export const useGFHLContext = () => {
  const context = useContext(FieldsDataHandlerContext);

  if (!context) {
    throw new Error('No FieldsDataHandlerContext found. This hook have to be used only inside appropriate context provider');
  }
  return context;
};
/**
 * @name useGenericFieldsData - hook to take a FieldsDataHandlerContext. Store schema, state data, actions to update data
 * @type IGenericFieldsHandlingLayer['data']
 */
export const useGenericFieldsData: () => PredefinedContextType['data'] = () => {
  const context = useGFHLContext();

  return context.data;
};
/**
 * @name useGenericFieldsData - hook to take a FieldsDataHandlerContext. Store schema, state data, actions to update data
 * @type IGenericFieldsHandlingLayer['actions']
 */
export const useGenericFieldsActions: () => PredefinedContextType['actions'] = () => {
  const context = useGFHLContext();

  return context.actions;
};
/**
 * @name useGenericFieldsData - hook to take a FieldsDataHandlerContext. Store schema, state data, actions to update data
 * @type IGenericFieldsHandlingLayer['actions']
 */
export const useSessionId: () => PredefinedContextType['session']['sessionId'] = () => {
  const context = useGFHLContext();

  return context.session.sessionId;
};
