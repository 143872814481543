import React from 'react';

import { WithChildren } from 'common/types';
import { ICustomOptionProps } from 'common/components/FieldGenerator/types';

import { ProjectFields } from 'common/types/fields';

import { useLengthTypeData } from 'containers/AdditionalDataStorage/context';
import { useGenericFieldsActionsAdopted } from 'containers/FormDataAdapter';
import AccessoriesRadioButton from 'views/AccessoriesSelectorView/components/AccessoriesRadioButton';
import { getSingleNumberValue } from '../../../../api/cart/utils';
import { toFixedByLengthType } from '../../../AssemblyLengthView/utils';
import { LengthType } from '../../../../common/types/additional';


const AccessoriesRadioButtonContainer = ({
  option,
}: WithChildren<ICustomOptionProps>) => {
  const lengthType = useLengthTypeData();
  const { getFieldsByName } = useGenericFieldsActionsAdopted();


  const alField = getFieldsByName([ProjectFields.assemblyLength])[ProjectFields.assemblyLength];
  const lengthInInches = getSingleNumberValue(alField?.value?.selected)!;
  const lengthInFeet = toFixedByLengthType(lengthInInches, LengthType.inches, LengthType.feet);
  const neededLenght = lengthType === LengthType.feet ? lengthInFeet : lengthInInches;

  const lengthWithType = `${neededLenght || 0} ${lengthType}`;
  return (
    <AccessoriesRadioButton
      option={option}
      length={lengthWithType}
    />
  );
};

export default AccessoriesRadioButtonContainer;
