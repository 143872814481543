import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, RegisterOptions, UseFormWatch } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { UseFormGetFieldState, UseFormResetField, UseFormSetValue } from 'react-hook-form/dist/types/form';

import { ChangeHandlerType, GeneratorChangeHandlerType, IFormValues } from 'common/components/FormHF/types';
import { FieldsType, ProjectFields, ValidationTypes } from 'common/types/fields';

import {
  isMaxLengthValidationType,
  isMaxValidationType,
  isMinLengthValidationType,
  isMinValidationType,
} from 'utils/isMinMaxValidation';
import { getValidationErrorMessage } from 'utils/getValidationErrorMessage';
import { generateFieldNameByGroup } from 'common/components/FormHF/utils';

import InputHF from 'common/components/FormHF/InputHF';
import SelectHF from 'common/components/FormHF/SelectHF';
import CheckboxesList from 'common/components/FormHF/CheckboxesList';
import RadioButtonsList from 'common/components/FormHF/RadioButtonsList';
import FieldWrapper from 'common/components/FieldWrapper';
import { useDebounceCallback } from 'hooks/useDebouncedCallback';
import { GeneratorFieldType, ICustomDescriptionProps, ICustomOptionProps } from './types';

interface FieldGeneratorProps<TKey extends string> {
  name: TKey,
  control: Control<Record<string, IFormValues[keyof IFormValues]>>,
  field: GeneratorFieldType,
  watch: UseFormWatch<Record<TKey, any>>,
  setValue: UseFormSetValue<Record<string, any>>,
  getFieldState: UseFormGetFieldState<Record<string, IFormValues[keyof IFormValues]>>,
  onChangeHandler?: GeneratorChangeHandlerType,
  isDisabled: boolean,
  isIgnoredForScrolling?: boolean,
  resetField: UseFormResetField<Record<string, IFormValues[keyof IFormValues]>>
  renderOptionLabelComponent?: (fieldId: ProjectFields) => React.FC<ICustomOptionProps> | undefined,
  renderDescriptionComponent?: (fieldId: ProjectFields) => React.FC<ICustomDescriptionProps> | undefined,
  className?: string,
}

const FieldGenerator = <TKey extends string = string>({
  name,
  control,
  field,
  watch,
  setValue,
  isDisabled,
  resetField,
  getFieldState,
  isIgnoredForScrolling,
  onChangeHandler,
  renderOptionLabelComponent,
  renderDescriptionComponent,
  className,
}: FieldGeneratorProps<TKey>) => {
  const isRangeInput: boolean = useMemo<boolean>(() => !!field.validation?.find((validation) => validation.type === ValidationTypes.range), [field.validation]);

  const rulesObject = useMemo<RegisterOptions>(() => {
    const rulesTemplate: RegisterOptions = {};
    field.validation?.forEach((v) => {
      if (v.type === ValidationTypes.required) {
        rulesTemplate.required = {
          value: true,
          message: getValidationErrorMessage(v),
        };
      } else if (v.type === ValidationTypes.range) {
        if (isMaxValidationType(v)) {
          rulesTemplate.max = {
            value: v.maxValue,
            message: getValidationErrorMessage(v),
          };
        }
        if (isMinValidationType(v)) {
          rulesTemplate.min = {
            value: v.minValue,
            message: getValidationErrorMessage(v),
          };
        }
      } else if (v.type === ValidationTypes.length && isMaxLengthValidationType(v)) {
        rulesTemplate.maxLength = {
          value: v.maxValue,
          message: getValidationErrorMessage(v),
        };
      } else if (v.type === ValidationTypes.length && isMinLengthValidationType(v)) {
        rulesTemplate.minLength = {
          value: v.minValue,
          message: getValidationErrorMessage(v),
        };
      } else if (v.type === ValidationTypes.pattern) {
        rulesTemplate.pattern = {
          value: new RegExp(v.pattern),
          message: getValidationErrorMessage(v),
        };
      }
    });
    return rulesTemplate;
  }, [field]);

  const onChangeHandlerDebounced = useDebounceCallback(onChangeHandler, 500);

  const onChangeHandlerWrapper = useCallback<ChangeHandlerType>((fieldVal) => {
    if (onChangeHandler) {
      switch (field.type) {
        case FieldsType.input: {
          onChangeHandlerDebounced(field.type, name as ProjectFields, fieldVal);
          break;
        }
        default: {
          onChangeHandler(field.type, name as ProjectFields, fieldVal);
        }
      }
    }
  }, [field.type, name, onChangeHandler, onChangeHandlerDebounced]);

  const renderOptionLabelComponentWrapper = useCallback(
    () => renderOptionLabelComponent && renderOptionLabelComponent(field.name),
    [field.name, renderOptionLabelComponent],
  );

  const Description = useMemo(() => {
    const Component = renderDescriptionComponent && renderDescriptionComponent(field.name);
    return Component || (() => null);
  }, [renderDescriptionComponent]);

  useEffect(() => {
    switch (field.type) {
      case FieldsType.input: {
        field.value?.selected
          ? setValue(field.name, field.value?.selected?.value)
          : resetField(field.name, { defaultValue: null });
        break;
      }
      case FieldsType.checkboxes: {
        field.value?.selected?.forEach((selectedItem) => {
          const itemName = generateFieldNameByGroup(name, selectedItem.id);
          setValue(itemName, selectedItem.id);
        });
        if (!field.value?.selected) {
          field.value?.options?.forEach((selectedItem) => {
            const itemName = generateFieldNameByGroup(name, selectedItem.id);
            resetField(itemName, { defaultValue: null });
          });
        }
        break;
      }
      case FieldsType.radioGroup: {
        field.value?.selected && field.value?.selected[0]
          ? setValue(field.name, field.value.selected[0].id)
          : resetField(field.name, { defaultValue: null });
        break;
      }
      case FieldsType.dropdown: {
        field.value?.selected
          ? setValue(field.name, field.value.selected[0]) // supported only single value
          : resetField(field.name, { defaultValue: null });
        break;
      }
      default:
        break;
    }
  }, [field, name, resetField, setValue]);

  const value = watch(name as any);
  const hasValue = !!value;

  switch (field.type) {
    case FieldsType.input: {
      return (
        <FieldWrapper
          className={className}
          name={name}
          hasValue={hasValue}
          isIgnoredForScrolling={isIgnoredForScrolling}
        >
          <InputHF
            name={name}
            label={field.title}
            control={control}
            rules={rulesObject}
            type={isRangeInput ? 'number' : 'text'}
            inputMode={isRangeInput ? 'decimal' : undefined}
            onChangeHandler={onChangeHandlerWrapper}
            disabled={isDisabled}
          />
          <Description field={field} />
        </FieldWrapper>
      );
    }
    case FieldsType.checkboxes: {
      if (field.value?.options) {
        return (
          <FieldWrapper
            className={className}
            name={name}
            hasValue={hasValue}
            isIgnoredForScrolling={isIgnoredForScrolling}
          >
            <CheckboxesList
              getFieldState={getFieldState}
              name={name}
              control={control}
              rules={rulesObject}
              setValue={setValue}
              watch={watch}
              optionsList={field.value?.options ?? []}
              selectedList={field.value?.selected}
              onChangeHandler={onChangeHandlerWrapper}
              disabled={isDisabled}
            />
            <Description field={field} />
          </FieldWrapper>
        );
      }
      return null;
    }
    case FieldsType.radioGroup: {
      return (
        <FieldWrapper
          className={className}
          name={name}
          hasValue={hasValue}
          isIgnoredForScrolling={isIgnoredForScrolling}
        >
          <RadioButtonsList
            getFieldState={getFieldState}
            name={name}
            control={control}
            rules={rulesObject}
            setValue={setValue}
            watch={watch}
            optionsList={field.value?.options ?? []}
            selectedList={field.value?.selected}
            onChangeHandler={onChangeHandlerWrapper}
            disabled={isDisabled}
            renderOptionLabelComponent={renderOptionLabelComponentWrapper}
          />
          <Description field={field} />
        </FieldWrapper>
      );
    }
    case FieldsType.dropdown: {
      return (
        <FieldWrapper
          className={className}
          name={name}
          hasValue={hasValue}
          isIgnoredForScrolling={isIgnoredForScrolling}
        >
          <SelectHF
            name={name}
            label={field.title}
            control={control}
            options={field.value?.options ?? []}
            rules={rulesObject}
            onChangeHandler={onChangeHandlerWrapper}
            disabled={isDisabled}
            renderOptionLabelComponent={renderOptionLabelComponentWrapper}
          />
          <Description field={field} />
        </FieldWrapper>
      );
    }
    default: return null;
  }
};

// @ts-ignore
export default FieldGenerator;
