import React, {
  SyntheticEvent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IStepperProps {
  steps: Array<string>,
  onClickStepper?: (stepTitle: string) => boolean,
  step?: string,
}

const Stepper = ({
  steps,
  onClickStepper,
  step,
  ...props
}: IStepperProps) => {
  const [currentStep, setCurrentStep] = useState<string>(step || steps[0]);

  const onStepHandler = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const { title } = event.currentTarget.dataset;
    if (title && ((onClickStepper && onClickStepper(title)) ?? true)) {
      setCurrentStep(title);
    }
  }, [onClickStepper, setCurrentStep]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, setCurrentStep]);
  return (
    <div
      {...props}
      className={styles.steps}
    >
      {steps.map((currStep, index) => (
        <div
          key={currStep}
          onClick={onStepHandler}
          onKeyDown={onStepHandler}
          data-title={currStep}
          className={classNames(styles.step, {
            [styles.current]: currentStep === currStep,
            [styles.complete]: index < steps.indexOf(currentStep),
          })}
          role="button"
          tabIndex={0}
        >
          <span className={styles.text}>{currStep}</span>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
