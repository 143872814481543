import React from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';

import { WithChildren } from 'common/types';

import styles from './styles.module.scss';

const Scrollbar = ({
  children,
  ...props
}: WithChildren<ScrollbarProps>) => (
  <Scrollbars
    renderTrackVertical={(renderProps) => (
      <div
        {...renderProps}
        className={styles.trackVertical}
      />
    )}
    renderThumbVertical={(renderProps) => (
      <div
        {...renderProps}
        className={styles.thumbVertical}
      />
    )}
    {...props}
  >
    {children}
  </Scrollbars>
);

export default Scrollbar;
