import React from 'react';
import {
  Control,
  Controller,
  RegisterOptions,
} from 'react-hook-form';

import Checkbox, { ICheckboxProps } from 'common/components/Checkbox';
import { IFormValues } from '../types';

interface ICheckboxHFProps<TKey extends string> extends ICheckboxProps {
  name: TKey,
  control: Control<Record<string, IFormValues[keyof IFormValues]>>,
  rules?: RegisterOptions,
  value: string,
  image?: string,
  onChangeHandler?: (fieldName: string, fieldVal: string, isSelected: boolean) => void,
}

const CheckboxHF = <TKey extends string = string>({
  name,
  label,
  description,
  control,
  rules,
  children,
  onChangeHandler,
  ...props
}: ICheckboxHFProps<TKey>) => (
  <Controller
    name={name}
    control={control}
    rules={{ ...rules }}
    render={({
      field,
      fieldState: { error },
    }) => (
      <Checkbox
        {...props}
        onChange={() => {
          if (onChangeHandler) {
            onChangeHandler(name, props.value, !field.value);
          }
          field.value ? field.onChange('') : field.onChange(props.value);
        }}
        error={error?.message}
        checked={!!field.value}
        name={name}
        label={label}
        description={description}
      >
        {children}
      </Checkbox>
    )}
  />
  );

export default CheckboxHF;
