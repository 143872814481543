// eslint-disable-next-line import/no-unresolved
import { UseFormSetError } from 'react-hook-form/dist/types/form';

import { IAxiosResponseError } from 'api/types';
import { IFormValues } from 'common/components/FormHF/types';
import { ToastTypes } from 'common/types/toasts';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE, DEFAULT_UNKNOWN_FIELD_ERROR_MESSAGE } from 'api/constants';

import { determineErrorType } from 'utils/determineErrorType';
import { isAxiosParseError } from 'utils/isAxiosParsedError';
import { showToast } from 'utils/toasts';
import { isUpdateFieldsError, isWholeSchemaError } from 'containers/GenericFieldsHandlingLayer/utils';


export const determineAndApplyErrorToForm = (
  error: IAxiosResponseError<unknown> | undefined,
  setError: UseFormSetError<Record<string, IFormValues[keyof IFormValues]>>,
) => {
  const parsedError = determineErrorType<unknown>(error);
  if (!isAxiosParseError(parsedError)) {
    showToast(ToastTypes.error, parsedError.message);
  } else if (isWholeSchemaError(parsedError)) {
    showToast(ToastTypes.error, parsedError.data?.message || DEFAULT_UNKNOWN_ERROR_MESSAGE);
  } else if (isUpdateFieldsError(parsedError)) {
    if (parsedError.data?.errors?.length) {
      parsedError.data?.errors.forEach((errorItem) => {
        errorItem.fieldId && setError(errorItem.fieldId, {
          message: errorItem.message || DEFAULT_UNKNOWN_FIELD_ERROR_MESSAGE,
        });
      });
    }
  }
};
