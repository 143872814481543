import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ILoaderProps {
  full?: boolean,
  middle?: boolean,
  className?: string
}

const Loader = ({
  full,
  middle,
  className,
}: ILoaderProps) => (
  <div
    className={classNames(styles.loader, className, {
      [styles.middle]: middle,
      [styles.full]: full,
    })}
  />
);

export const FormLoader = ({
  full,
  middle,
  className,
}: ILoaderProps) => (
  <div className={styles.formLoaderWrapper}>
    <Loader
      className={className}
      full={full}
      middle={middle}
    />
  </div>
);

export default Loader;
