export enum FieldsType {
  input = 'input',
  dropdown = 'dropdown',
  checkboxes = 'checkboxes',
  radioGroup = 'radioGroup',
}

export enum ProjectFields {
  hoseApplicationAttribute = 'hoseApplicationAttribute',
  hoseInsideDiameter = 'hoseInsideDiameter',
  hosePressure = 'hosePressure',
  hoseProduct = 'hoseProduct',
  hoseMaterialConstruction = 'hoseMaterialConstruction',
  preselectedHoseProduct = 'preselectedHoseProduct',
  fitting1ConnectionType = 'fitting1ConnectionType',
  fitting1ConnectionSize = 'fitting1ConnectionSize',
  // fitting1Shape = 'fitting1Shape',
  fitting1Product = 'fitting1Product',
  fitting1Gender = 'fitting1Gender',
  fitting2ConnectionType = 'fitting2ConnectionType',
  fitting2ConnectionSize = 'fitting2ConnectionSize',
  // fitting2Shape = 'fitting2Shape',
  fitting2Product = 'fitting2Product',
  fitting2Gender = 'fitting2Gender',
  assemblyLength = 'assemblyLength',
  angleOfRotation = 'angleOfRotation',
  accessories = 'accessories',
  options = 'options',
  testingConfiguration = 'testingConfiguration',
  customerSerialNumber = 'customerSerialNumber',
  requiredComponents = 'requiredComponents',
  fitting2SameAs1 = 'fitting2SameAs1',
}

export enum ValidationTypes {
  range = 'range',
  length = 'length',
  pattern = 'pattern',
  required = 'required',
}

export interface IRangeMinValidationDetails {
  type: ValidationTypes.range,
  minValue: number,
  promt?: string,
}

export interface IRangeMaxValidationDetails {
  type: ValidationTypes.range,
  maxValue: number,
  promt?: string,
}

export interface ILengthMinValidationDetails {
  type: ValidationTypes.length,
  minValue: number,
  promt?: string,
}

export interface ILengthMaxValidationDetails {
  type: ValidationTypes.length,
  maxValue: number,
  promt?: string,
}

export interface IRequiredValidationDetails {
  type: ValidationTypes.required,
  promt?: string,
}

export interface IPatternValidationDetails {
  type: ValidationTypes.pattern,
  pattern: string,
  promt?: string,
}

export type ValidationDetailsType = IRangeMaxValidationDetails
  | ILengthMinValidationDetails
  | ILengthMaxValidationDetails
  | IRangeMinValidationDetails
  | IRequiredValidationDetails
  | IPatternValidationDetails;

export type StringItemAttribute = {
  [key: string]: string,
}

export interface EntityMetaAttributes {
  title: string, // to show text in a dropdown
  id: string,
  image?: string,
  sku?: string,
  description?: string,
  details?: string,
  part_number?: string,
  temperature_low?: string,
  temperature_high?: string,
  pressure?: string,
  pressure_rating?: string,
  product_type?: string,
  brand?: string,
}

export interface IServerItemOption {
  id: string, // potentially - SKU number
  title: string, // What does the user will see in the dropdown
  attributes?: EntityMetaAttributes | StringItemAttribute,
}

export interface IServerTextOption {
  value: string | number,
}

export type ServerOption = IServerItemOption | IServerTextOption;

/**
 * @deprecated
 */
export type IServerFieldStateOptions<TField extends FieldsType = FieldsType> = TField extends FieldsType.input
    ? never
    : TField extends FieldsType.checkboxes | FieldsType.radioGroup | FieldsType.dropdown
        ? Array<IServerItemOption>
        : Array<ServerOption>;

/**
 * @deprecated
 */
export type IServerFieldStateSelected<TField extends FieldsType = FieldsType> = TField extends FieldsType.input
    ? IServerTextOption
    : TField extends FieldsType.checkboxes | FieldsType.radioGroup | FieldsType.dropdown
        ? Array<IServerItemOption>
        : IServerTextOption | Array<IServerItemOption>;

export interface IServerBaseFieldState {
  /**
   *
   * @name name - known id of the field. With each view-step was associated list of the field ids,
   * that have to be shown on that view
   */
  name: ProjectFields;
  value?: unknown;
  /**
   * @name status - field status
   */
  status?: 'complete' | 'disabled' | 'incomplete',
}

export interface IServerFieldMultiSelectState extends IServerBaseFieldState {
  value?: {
    options?: Array<IServerItemOption>
    selected?: Array<IServerItemOption>
  }
}

export interface IServerFieldSingleSelectState extends IServerBaseFieldState {
  value?: {
    options?: Array<IServerItemOption>
    selected?: IServerItemOption
  }
}

export interface IServerFieldInputState extends IServerBaseFieldState {
  value?: {
    selected?: IServerTextOption
  }
}

/**
 * @deprecated
 */
export interface IServerFieldState<TField extends FieldsType = FieldsType> {
  name: ProjectFields,
  value?: {
    /**
     * @name options - list of the available option for the checkbox and dropdown fields
     */
    options?: IServerFieldStateOptions<TField>,
    /**
     * @name selected - list of the selected options for the checkbox and dropdown fields
     */
    selected?: IServerFieldStateSelected<TField>,
  }
  /**
   * @name status - field status
   */
  status?: 'complete' | 'disabled' | 'incomplete',
}

export interface IServerFieldSchema<TField extends FieldsType = FieldsType> {
  /**
   * @name name - known id of the field. With each view-step was associated list of the field ids,
   * that have to be shown on that view
   */
  name: ProjectFields,
  /**
   * @name type - known field type that could be translated to form component
   */
  type: TField,
  /**
   * @name title - title (name) of the field
   */
  title?: string, // Title of the field
  /**
   * @type Array<ValidationDetailsType>
   * @name validation - array of the validation rules needs to be verified on the FE side
   */
  validation?: Array<ValidationDetailsType>,
}

export interface ISelectedFieldData {
  fieldId: ProjectFields, // potentially - SKU number
  value?: string | number | string[];
}

export type IServerFieldsUpdate = Record<string, string | number | string[] | undefined>

/**
 * @deprecated
 */
export type PartialOptionDependent<T, K extends PropertyKey = PropertyKey, FieldType = FieldsType> = FieldType extends FieldsType.input
  ? Omit<T, Extract<keyof T, K>>// { [P in Exclude<keyof T, K>]: T[P] }
  : T;

/**
 * @deprecated
 */
type FieldTypeDependent<FieldType extends FieldsType = FieldsType> = PartialOptionDependent<IServerFieldState<FieldType>, 'options', FieldType>;

export type PartialK<T, K extends PropertyKey = PropertyKey> = { [key in Exclude<keyof T, K>]: T[key] }
  & { [key in Extract<keyof T, K>]?: T[key] };

/**
 * @deprecated
 */
export type ServerFieldTypeGenerator<FieldType extends FieldsType = FieldsType> = FieldTypeDependent<FieldType> & IServerFieldSchema<FieldType>

export type InputFieldStateType = IServerFieldSchema<FieldsType.input> & IServerFieldInputState;
export type DropdownFieldStateType = IServerFieldSchema<FieldsType.dropdown> & IServerFieldMultiSelectState;
export type CheckboxFieldStateType = IServerFieldSchema<FieldsType.checkboxes> & IServerFieldMultiSelectState;
export type RadioFieldStateType = IServerFieldSchema<FieldsType.radioGroup> & IServerFieldMultiSelectState;

export type UnitedFieldState = InputFieldStateType | DropdownFieldStateType | CheckboxFieldStateType | RadioFieldStateType;
