export enum LengthType {
  feet = 'feet',
  inches = 'inches',
}

export enum SelectedConfiguration {
  hose = 'hose',
  fitting1 = 'fitting1',
  fitting2 = 'fitting2',
}
