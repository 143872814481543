import React from 'react';

import { ICustomOptionProps } from 'common/components/FieldGenerator/types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const HoseProductOption = ({
  option: { title, attributes },
}: ICustomOptionProps) => {
  const attrCount = [attributes?.pressure_rating, attributes?.temperature_high && attributes?.temperature_low].filter((v) => !!v).length;
  const gridClass = classNames({
    [styles.content]: true,
    [styles.content2]: attrCount === 1,
    [styles.content3]: attrCount === 2,
  });
  return (
    <>
      {attributes?.image && (
        <div className={styles.image}>
          <img
            src={attributes?.image}
            alt={title}
          />
        </div>
      )}
      <div className={gridClass}>
        <span>{title}</span>
        {attributes?.pressure_rating
        && (
          <span className={styles.more}>
            {attributes?.pressure_rating}
          </span>
        )}
        {attributes?.temperature_high && attributes?.temperature_low && (
          <span className={styles.more}>
            Low
            {' '}
            {attributes?.temperature_low}
            -
            High
            {' '}
            {attributes?.temperature_high}
          </span>
        )}
      </div>
    </>
  );
};
export default HoseProductOption;
