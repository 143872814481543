import { DefaultToFormAdaptersType, IToServiceAdapters } from './types';
import {
  adoptCheckboxToForm,
  adoptCheckboxToService,
  adoptRadioToForm,
  adoptRadioToService,
  adoptDropdownToForm,
  adoptDropdownToService,
  adoptInputToForm,
  adoptInputToService,
} from './adapters';


export const defaultToFormAdapters: DefaultToFormAdaptersType = {
  dropdownToFormAdapter: adoptDropdownToForm,
  checkboxToFormAdapter: adoptCheckboxToForm,
  radioToFormAdapter: adoptRadioToForm,
  inputToFormAdapter: adoptInputToForm,
};

export const defaultToServiceAdapters: Required<IToServiceAdapters> = {
  inputToServiceAdapter: adoptInputToService,
  dropdownToServiceAdapter: adoptDropdownToService,
  checkboxToServiceAdapter: adoptCheckboxToService,
  radioToServiceAdapter: adoptRadioToService,
};
