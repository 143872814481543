import React from 'react';
import classNames from 'classnames';

import { WithChildren } from 'common/types';

import { ICustomOptionProps } from 'common/components/FieldGenerator/types';

import styles from './styles.module.scss';

export interface IAccessoriesRadioButtonProps extends ICustomOptionProps {
  length?: string,
}

const AccessoriesRadioButton = ({
  length = '0 feet',
  option,
}: WithChildren<IAccessoriesRadioButtonProps>) => (
  <>
    <label
      htmlFor={option.id}
      className={classNames(styles.radioButton)}
    >
      {option.attributes?.brand}
      {option.attributes?.product_type ? ` - ${option.attributes?.product_type}` : ''}
      {' '}
      {option.attributes?.sku}
    </label>
    <div>
      <span className={styles.length}>{length}</span>
      {' - '}
      {option?.attributes?.description}
    </div>
  </>
);

export default AccessoriesRadioButton;
