import React from 'react';

import type { ICustomOptionProps } from 'common/components/FieldGenerator/types';

import styles from './styles.module.scss';

const SelectOption: React.FC<ICustomOptionProps> = ({
  option: { title, attributes },
}) => (
  <>
    {attributes?.image && (
      <div className={styles.image}>
        <img
          src={attributes?.image}
          alt={title}
        />
      </div>
    )}
    <div className={styles.content}>
      <span>{title}</span>
      {attributes?.details && <span className={styles.more}>{attributes?.details}</span>}
    </div>
  </>
);

export default SelectOption;
