import React, { useCallback, useMemo, useState } from 'react';

import type { WithChildren } from 'common/types';
import { LengthType, SelectedConfiguration } from 'common/types/additional';

import type { AnyDataType, IAdditionalSataStorageContext } from './types';
import { IBaseDataStorage } from './types';
import { AdditionalDataStorageContext } from './context';


export const AdditionalDataStorage = ({ children }: WithChildren<{}>) => {
  const [lengthType, setLengthType] = useState<IBaseDataStorage['lengthType']>(LengthType.inches);
  const [reqComp, setReqComp] = useState<IBaseDataStorage['requiredComponents']>(undefined);
  const [configQuantity, setConfigQuantity] = useState<IBaseDataStorage['configQuantity']>(1);
  const [tosAgreed, setTosAgreed] = useState<IBaseDataStorage['tosAgreed']>(false);
  const [activeSelectedConfiguration, setActiveSelectedConfiguration] = useState<IBaseDataStorage['activeSelectedConfiguration']>(SelectedConfiguration.hose);
  const [anyData, setAnyData] = useState<AnyDataType>({});

  const getData = useCallback<IAdditionalSataStorageContext['actions']['getData']>(
    (key) => {
      switch (key) {
        case 'lengthType': {
          return lengthType;
        }
        case 'tosAgreed': {
          return tosAgreed;
        }
        case 'requiredComponents': {
          return reqComp;
        }
        case 'activeSelectedConfiguration': {
          return activeSelectedConfiguration;
        }
        case 'configQuantity': {
          return configQuantity;
        }
        default: {
          return anyData[key];
        }
      }
    },
    [lengthType, reqComp, activeSelectedConfiguration, configQuantity, anyData, tosAgreed],
  );

  const setData = useCallback<IAdditionalSataStorageContext['actions']['setData']>(
    (key, value) => {
      switch (key) {
        case 'lengthType': {
          setLengthType(value);
          break;
        }
        case 'requiredComponents': {
          setReqComp(value);
          break;
        }
        case 'activeSelectedConfiguration': {
          setActiveSelectedConfiguration(value);
          break;
        }
        case 'quantity': {
          setConfigQuantity(value);
          break;
        }
        case 'tosAgreed': {
          setTosAgreed(value);
          break;
        }
        default: {
          setAnyData((currData) => ({
            ...currData,
            [key]: value,
          }));
        }
      }
    },
    [setLengthType, setReqComp, setActiveSelectedConfiguration, setAnyData, setTosAgreed],
  );

  const contextValue = useMemo<IAdditionalSataStorageContext>(() => ({
    data: {
      lengthType,
      tosAgreed,
      requiredComponents: reqComp,
      activeSelectedConfiguration,
      configQuantity,
      ...anyData,
    },
    actions: {
      getData,
      setData,
    },
  }), [lengthType, reqComp, activeSelectedConfiguration, configQuantity, tosAgreed, anyData, getData, setData]);

  return (
    <AdditionalDataStorageContext.Provider value={contextValue}>
      {children}
    </AdditionalDataStorageContext.Provider>
  );
};
