import type { IFieldsUpdate, IWholeSchemaWithState } from 'containers/GenericFieldsHandlingLayer/types';
import type { IServerFieldState } from 'common/types/fields';

import {
  WHOLE_SCHEMA_DATA,
} from 'containers/FakeDataService/fakeData';
import { FakeDataService } from 'containers/FakeDataService';

import { ProjectFields, UnitedFieldState } from 'common/types/fields';
import { requestHandlerFabric } from './instance';
import { API_ENDPOINTS } from './routes';

export const updateFields = async (sessionId: string, updatedFields: IFieldsUpdate) => {
  const result = (await requestHandlerFabric().post<{ state: Array<UnitedFieldState> }>(API_ENDPOINTS.updateFieldsState(sessionId), updatedFields)).data;
  // eslint-disable-next-line no-param-reassign,no-return-assign
  // result.state.forEach((f) => { f.isDisabled = f.isDisabled ?? false; }); // TODO: remove
  return result.state;
};

export const printPDF = async (sessionId: string, content: string) => {
  const result = (await requestHandlerFabric().post<{ url: string }>(API_ENDPOINTS.printPDF(), {
    content,
    sessionId,
  })).data;
  // eslint-disable-next-line no-param-reassign,no-return-assign
  // result.state.forEach((f) => { f.isDisabled = f.isDisabled ?? false; }); // TODO: remove
  return result.url;
};

export const getSchemaWithCache = async (sessionId?: string, params?: Record<string, string>) => {
  const result = (await requestHandlerFabric().get<IWholeSchemaWithState>(API_ENDPOINTS.getWholeSchema(sessionId, params))).data;

  const hose = params ? params.hose : undefined;
  if (hose && !sessionId) {
    const { session } = result;
    const withHoseState = await updateFields(session.sessionId, {
      sessionId: session.sessionId,
      data: {
        [ProjectFields.preselectedHoseProduct]: hose,
      },
    });
    result.state = withHoseState;
  }
  // eslint-disable-next-line no-param-reassign,no-return-assign
  // result.state.forEach((f) => { f.isDisabled = f.isDisabled ?? false; }); // TODO: remove
  return result;
};


/**
 * @deprecated
 */
// eslint-disable-next-line no-unused-vars
export const getSchemaWithCacheFake = async (sessionId?: string, params?: Record<string, string>) => (
  new Promise<IWholeSchemaWithState>((resolve) => {
    setTimeout(() => resolve(WHOLE_SCHEMA_DATA), 250 + 500 * Math.random());
  })
);

/**
 * @deprecated
 */
// eslint-disable-next-line no-unused-vars
export const getSchemaWithCacheFakeError = async (sessionId?: string) => (
  new Promise<IWholeSchemaWithState>((resolve, reject) => {
    // eslint-disable-next-line prefer-promise-reject-errors
    setTimeout(() => reject({
      data: {
        message: 'ERROR ERROR ERROR!!!',
        errors: {
          session: 'ERROR ERROR ERROR!!! SESSION',
        },
      },
      status: 404,
      rawError: { status: 404, message: 'ERROR ERROR ERROR!!! GLOBAL' },
      isAxiosError: true,
    }), 750 + 1000 * Math.random());
  })
);

/**
 * @deprecated
 */
export const updateFieldsFake = async (sessionId: string, updatedFields: IFieldsUpdate) => (
  new Promise<Array<IServerFieldState>>((resolve) => {
    setTimeout(() => {
      const fakeService = FakeDataService.getInstance();
      resolve(fakeService.updateState(updatedFields));
    }, 1000 * Math.random());
  })
);

/**
 * @deprecated
 */
// eslint-disable-next-line no-unused-vars
export const updateFieldsFakeError = async (sessionId: string, updatedFields: IFieldsUpdate) => (
  new Promise<Array<UnitedFieldState>>((resolve, reject) => {
    setTimeout(() => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        data: {
          message: 'ERROR ERROR ERROR!!!',
          errors: [{
            fieldId: ProjectFields.hoseApplicationAttribute,
            message: 'ERROR ERROR ERROR!!! hoseApplicationAttribute',
          }, {
            fieldId: ProjectFields.hoseInsideDiameter,
            message: 'ERROR ERROR ERROR!!! hoseInsideDiameter',
          }],
        },
        status: 404,
        rawError: { status: 404, message: 'ERROR ERROR ERROR!!! GLOBAL' },
        isAxiosError: true,
      });
    }, 500 + 1000 * Math.random());
  })
);
