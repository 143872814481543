import * as React from 'react';
import classNames from 'classnames';

import type { WithChildren } from 'common/types';

import styles from './styles.module.scss';

interface IFormWrapperProps {
  header: string;
  description?: string,
  buttonsBlock?: React.ReactNode
}

/**
 * Form wrapper to wrap default styles for each view
 */
const FormWrapper = ({
  children,
  header,
  description,
}: WithChildren<IFormWrapperProps>) => (
  <div className={classNames(styles.wrapper)}>
    <div className={classNames(styles.form)}>
      <div className={classNames(styles.content)}>
        <h1 className={styles.title}>{header}</h1>
        {description && (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {
          React.Children.map(children, (child, index) => {
            if (React.Children.count(children) - 1 !== index) {
              return child;
            }
            return null;
          })
        }
      </div>
      {React.Children.count(children) > 1 && React.Children.toArray(children)[React.Children.count(children) - 1]}
    </div>
  </div>
);

export default FormWrapper;
