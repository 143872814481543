import React from 'react';

import styles from './styles.module.scss';

interface IEmptyBodyProps {
  emptyBodyLabel: string,
  defaultImage: string,
}

const EmptyBody = ({
  emptyBodyLabel,
  defaultImage,
}: IEmptyBodyProps) => (
  <>
    <div className={styles.image}>
      <img
        src={defaultImage}
        alt=""
      />
    </div>
    <span className={styles.label}>{emptyBodyLabel}</span>
  </>
);

export default EmptyBody;
