import { useCallback, useMemo } from 'react';

import { useGenericFieldsActions, useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';

import type { IStorage } from 'containers/GenericFieldsHandlingLayer/types';
import type { IFormValues } from 'common/components/FormHF/types';
import type { IAxiosResponseError } from 'api/types';
import { FieldsType, ProjectFields } from 'common/types/fields';

import { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import { DefaultToFormAdaptersFieldType, IToFormAdapters, IToServiceAdapters } from './types';
import { defaultToServiceAdapters } from './constants';
import { useAdapters } from './hooks';
import { getUpdatedFieldToForm, getUpdatedFieldToService } from './utils';


export const useGenericFieldsDataAdopted = <TField = DefaultToFormAdaptersFieldType>(
  toFormAdapters?: IToFormAdapters<TField>,
  toServiceAdapters: IToServiceAdapters = defaultToServiceAdapters,
) => {
  const { toForm } = useAdapters<TField>(toFormAdapters, toServiceAdapters);
  const {
    error, isLoading, order, fields,
  } = useGenericFieldsData();

  const preparedData = useMemo<IStorage<ProjectFields, TField, IAxiosResponseError<any>>['fields']>(() => {
    const result = Object.entries(fields).reduce((acc, [fieldKey, field]) => {
      acc[fieldKey as ProjectFields] = getUpdatedFieldToForm<TField>(field, toForm);
      return acc;
    }, {} as IStorage<ProjectFields, TField, IAxiosResponseError<any>>['fields']);
    return result;
  }, [toForm, fields]);

  return {
    error,
    isLoading,
    order,
    fields: preparedData,
  };
};

export const useGenericFieldsActionsAdopted = <TField = DefaultToFormAdaptersFieldType>(
  toFormAdapters?: IToFormAdapters<TField>,
  toServiceAdapters: IToServiceAdapters = defaultToServiceAdapters,
) => {
  const { toService, toForm } = useAdapters<TField>(toFormAdapters, toServiceAdapters);
  const { getFieldsByName, updateFieldsState } = useGenericFieldsActions();
  const { fields } = useGenericFieldsData();

  const getFieldsByNameAdopted:
    (fieldsNames: Array<ProjectFields>) => ConfiguredRecordType<ProjectFields, TField> = useCallback(
      (fieldsNames: Array<ProjectFields>) => {
        const neededFields = getFieldsByName(fieldsNames);
        return Object.entries(neededFields).reduce((acc, [fieldKey, field]) => {
          acc[fieldKey as ProjectFields] = getUpdatedFieldToForm<TField>(field, toForm);
          return acc;
        }, {} as ConfiguredRecordType<ProjectFields, TField>);
      },
      [getFieldsByName, toForm],
    );

  /**
   * @name updateFieldsStateAdopted
   * @description data adapter to change format of the changed field value in form to required format of GenericFieldsHandlingLayer
   */
  const updateFieldsStateAdopted = useCallback((fieldType: FieldsType, fieldName: ProjectFields, fieldVal?: IFormValues[keyof IFormValues]) => {
    const adoptedField = getUpdatedFieldToService(fieldType, fieldName, fields[fieldName], toService, fieldVal);
    const push = adoptedField ? [adoptedField] : [];
    /** const { [fieldName]: remove, ...others } = fields;
    console.log('removed', remove);
    console.log('others', others);
    console.log('new', adoptedField);
    const format = Object.values(others).map((field) => getStoredFieldToService(
      field,
      toService,
    ));

    console.log('format', format); * */
    updateFieldsState([...push]); // NOTE: here we can append all fields state if needed
  }, [fields, toService, updateFieldsState]);

  return {
    getFieldsByName: getFieldsByNameAdopted,
    updateFieldsState: updateFieldsStateAdopted,
  };
};
