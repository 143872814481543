import React from 'react';
import {
  Control,
  Controller,
  RegisterOptions,
} from 'react-hook-form';

import type { OptionType } from 'common/components/Select/types';
import { IServerItemOption } from 'common/types/fields';
import type { ChangeHandlerType } from 'common/components/FormHF/types';
import { IFormValues } from 'common/components/FormHF/types';
import Select, { ISelectProps } from 'common/components/Select';
import { ICustomOptionProps } from 'common/components/FieldGenerator/types';

interface ISelectHFProps<TKey extends string> extends Omit<ISelectProps, 'value' |'name'> {
  name: TKey,
  control: Control<Record<string, IFormValues[keyof IFormValues]>>,
  rules?: RegisterOptions,
  onChangeHandler?: ChangeHandlerType,
  renderOptionLabelComponent?: () => React.FC<ICustomOptionProps> | undefined,
}

const SelectHF = <TKey extends string>({
  name,
  label,
  control,
  rules,
  onChangeHandler,
  ...props
}: ISelectHFProps<TKey>) => (
  <Controller
    name={name}
    control={control}
    rules={{ ...rules }}
    render={({
      field,
      fieldState: { error },
    }) => (
      <Select
        {...props}
        name={name}
        label={label}
        value={field.value as IServerItemOption} // TODO fix type
        onChange={(newValue: OptionType | null) => {
          if (onChangeHandler) {
            onChangeHandler(newValue ?? undefined);
          }
          field.onChange(newValue);
        }}
        required={!!rules?.required}
        error={error?.message}
      />
    )}
  />
  );

// @ts-ignore
export default SelectHF;
