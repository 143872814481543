import React from 'react';
import classNames from 'classnames';
import { WithChildren } from 'common/types';
import styles from './styles.module.scss';

const FieldWrapper = ({
  className, children, name, hasValue, isIgnoredForScrolling,
}: WithChildren<{ className?: string, name: string, hasValue: boolean, isIgnoredForScrolling?: boolean }>) => (
  <div
    className={classNames(styles.wrapper, className)}
    data-field-wrapper={name}
    data-has-value={hasValue ? 1 : 0}
    data-ignore-scroll-to={isIgnoredForScrolling ? 1 : 0}
  >
    {children}
  </div>
);

export default FieldWrapper;
