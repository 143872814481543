import type { UnitedFieldState } from 'common/types/fields';

import { useQueryWithParsedError } from 'hooks/useQueryWithParsedError';
import { getSchemaWithCache, updateFields } from 'api/requestFuncs';
import { ISelectedFieldData } from 'common/types/fields';


export const useWholeSchemaRequest = (sessionId?: string, params?: Record<string, string>) => (
  useQueryWithParsedError(
    ['whole-schema', sessionId],
    () => getSchemaWithCache(sessionId, params),
    {
      cacheTime: 5000,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
);

export const useFieldsUpdateHandler = (
  updatedFields: Array<ISelectedFieldData> = [],
  sessionId?: string,
  handleFieldsUpdated?: (data: Array<UnitedFieldState>) => void,
) => (
  useQueryWithParsedError(
    ['fields-update-handler', sessionId, updatedFields],
    () => {
      if (sessionId && updatedFields.length) {
        return updateFields(
          sessionId,
          {
            sessionId,
            data: updatedFields.reduce((acc, val) => ({
              ...acc,
              [val.fieldId]: val.value,
            }), {}),
          },
        );
      }
      return Promise.resolve([]);
    },
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data: Array<UnitedFieldState>) => {
        handleFieldsUpdated && handleFieldsUpdated(data);
      },
    },
  )
);
