import { useEffect, useMemo } from 'react';

import type { PredefinedContextType } from 'containers/GenericFieldsHandlingLayer/context';
import type { IFormValues } from 'common/components/FormHF/types';
import { FieldsType, ProjectFields } from 'common/types/fields';

import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import { DefaultToFormAdaptersFieldType } from 'containers/FormDataAdapter/types';
import { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import { isGeneratorDropdownFieldType } from 'utils/isGeneratorFieldType';
import { DEFAULT_ANGLE_OPTION } from 'views/AngleRotationView/constants';


// this hook could be useless unless we have useEffect hook in FieldGenerator
// which has applied selected props from field property to form
export const usePrepareDefaultAngleOfRotationFormData = (fields: PredefinedContextType['data']['fields']): IFormValues => useMemo(() => {
  const defaultValues = prepareDefaultFormData(fields);
  const angleOfRotationField = fields[ProjectFields.angleOfRotation];
  if (angleOfRotationField && isGeneratorDropdownFieldType(angleOfRotationField)) {
    let angleVal = angleOfRotationField?.value?.selected ? angleOfRotationField.value.selected[0] : undefined;
    if (!angleVal) {
      angleVal = angleOfRotationField?.value?.options?.length ? angleOfRotationField.value.options[0] : undefined;
    }

    return {
      ...defaultValues,
      ...(angleVal ? { [ProjectFields.angleOfRotation]: angleVal } : {}),
    };
  }
  console.error('Angle of rotation field has unexpected field type and can be used properly');
  return defaultValues;
}, []); // TODO check this later

export const useAngleOfRotationFieldWithDefaultSelected = <TField = DefaultToFormAdaptersFieldType>(
  fields: ConfiguredRecordType<ProjectFields, TField>,
  updateFieldsState: (fieldType: FieldsType, fieldName: ProjectFields, fieldVal?: IFormValues[keyof IFormValues]) => void,
) => {
  useEffect(() => {
    const aorField = fields[ProjectFields.angleOfRotation];
    if (aorField && isGeneratorDropdownFieldType(aorField)) {
      if (!aorField.value?.selected) {
        const selected = aorField.value?.options ? aorField.value.options[0] : DEFAULT_ANGLE_OPTION;
        updateFieldsState(FieldsType.dropdown, ProjectFields.angleOfRotation, selected);
      }
    }
  }, [fields, updateFieldsState]);

  return useMemo(() => {
    const aorField = fields[ProjectFields.angleOfRotation];
    if (aorField && isGeneratorDropdownFieldType(aorField)) {
      const adoptedAOR = {
        ...aorField,
      };
      if (!aorField.value?.selected) {
        adoptedAOR.value!.selected = [aorField.value?.options ? aorField.value.options[0] : DEFAULT_ANGLE_OPTION];
      }
      return adoptedAOR;
    }
    return undefined;
  }, [fields]);
};
