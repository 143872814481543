import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction, QueryKey, UseQueryOptions, UseQueryResult,
} from '@tanstack/react-query';

import { IAxiosResponseError } from 'api/types';
import { isAxiosParseError } from 'utils/isAxiosParsedError';
import { makeDefaultError } from 'utils/makeDefaultError';

type ExtendedUseQueryOptionsType<TQueryFnData> = {
  onParsedError?: (error: IAxiosResponseError<TQueryFnData>) => void,
  errorTextFallback?: string,
};

type ExtendedUseQueryResultType<TData> = {
  parsedError?: IAxiosResponseError<TData>,
};

export function useQueryWithParsedError<
  TQueryFnData, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFunc: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & ExtendedUseQueryOptionsType<TQueryFnData>,
): UseQueryResult<TData, TError> & ExtendedUseQueryResultType<TQueryFnData> {
  let onErrorWrapper;
  if (options && options.onParsedError) {
    onErrorWrapper = (error: TError) => {
      let parsedError: ExtendedUseQueryResultType<TQueryFnData>['parsedError'];

      if (!isAxiosParseError<TQueryFnData>(error)) {
        parsedError = makeDefaultError(error);
      } else {
        parsedError = error;
      }
      options.onParsedError!(parsedError);
    };
  }

  const useQueryResult = useQuery(
    queryKey,
    queryFunc,
    {
      ...options,
      onError: onErrorWrapper || options?.onError,
    },
  );
  let parsedError: ExtendedUseQueryResultType<TQueryFnData>['parsedError'];
  if (useQueryResult.error) {
    if (!isAxiosParseError<TQueryFnData>(useQueryResult.error)) {
      parsedError = makeDefaultError(useQueryResult.error);
    } else {
      parsedError = useQueryResult.error;
    }
  }

  return { ...useQueryResult, parsedError };
}
