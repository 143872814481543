import React from 'react';
import {
  Control,
  Controller,
  RegisterOptions,
} from 'react-hook-form';

import Input, { IInputProps } from 'common/components/Input';
import { ChangeHandlerType, IFormValues } from '../types';

interface IInputHFProps<TKey extends string> extends IInputProps {
  name: TKey,
  control: Control<Record<string, IFormValues[keyof IFormValues]>>,
  rules?: RegisterOptions,
  onChangeHandler?: ChangeHandlerType,
}

const InputHF = <TKey extends string = string>({
  name,
  control,
  rules,
  onChangeHandler,
  ...props
}: IInputHFProps<TKey>) => (
  <Controller
    name={name}
    control={control}
    rules={{ ...rules }}
    render={({
      field,
      fieldState: { error },
    }) => (
      <Input
        {...props}
        onChange={(event) => {
          field.onChange(event.target.value);
          if (onChangeHandler) {
            onChangeHandler(event.target.value);
          }
        }}
        ref={field.ref}
        value={field.value ? field.value as string : ''}
        name={name}
        error={error?.message}
      />
    )}
  />
  );

// @ts-ignore
export default InputHF;
