/* eslint-disable max-len */
import { IServerItemOption } from 'common/types/fields';
import rawPrintStyle from 'utils/print';
import { LengthType } from 'common/types/additional';

export const ItemTemplate = ({
  title, image, label, className,
}: { title?: string, image?: string, className?: string, label: string }) => `
   <div class="item ${className}">
    <a>${label}</a>
    <div>
      ${image ? `<img src="${image}" alt="" />` : ''}
      <div>${title}</div>
    </div>
  </div>
`;

export const DocumentTemplate = ({
  hose,
  fitting1,
  fitting2,
  angle,
  accessories,
  customerPartNumber,
  lengthInInches,
  lengthInFeet,
  testing,
  lengthType,
}: {
        hose?: IServerItemOption,
        fitting1?: IServerItemOption,
        fitting2?: IServerItemOption,
        accessories?: IServerItemOption,
        testing?: IServerItemOption,
        angle?: number,
        lengthInInches: number,
        lengthInFeet: number,
        lengthType: LengthType,
        customerPartNumber: string,
    }, quantity: number) => {
  const items = [
    ItemTemplate({ title: hose?.title, image: hose?.attributes?.image, label: 'Hose Selection' }),
    ItemTemplate({ title: fitting1?.title, image: fitting1?.attributes?.image, label: 'Fitting 1' }),
    ItemTemplate({ title: fitting2?.title, image: fitting2?.attributes?.image, label: 'Fitting 2' }),
    ItemTemplate({
      title: lengthType === LengthType.feet ? (`${lengthInFeet} feet`) : (`${lengthInInches} inches`),
      label: 'Overall Assembly Length',
    }),
    (typeof angle !== 'undefined') ? ItemTemplate({ title: `${angle}°`, label: 'Angle of Rotation' }) : '',
    ItemTemplate({
      title: accessories?.title,
      image: accessories?.attributes?.image,
      label: 'Accessories',
      className: 'alt',
    }),
    customerPartNumber ? ItemTemplate({ title: customerPartNumber, label: 'Customer Part Number' }) : '',
    quantity ? ItemTemplate({ title: quantity.toFixed(0), label: 'Assembly Qty' }) : '',
    testing ? ItemTemplate({
      title: `<svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.6684 0.382523C12.9276 0.134418 13.2725 -0.00274376 13.6304 -2.12689e-05C13.9883 0.00270122 14.3311 0.145096 14.5865 0.397115C14.842 0.649135 14.9901 0.991068 14.9995 1.35076C15.009 1.71046 14.8791 2.05978 14.6372 2.32502L7.29535 11.5565C7.16911 11.6932 7.01674 11.8029 6.84735 11.8791C6.67797 11.9552 6.49505 11.9962 6.30954 11.9997C6.12402 12.0032 5.93972 11.969 5.76764 11.8992C5.59557 11.8294 5.43927 11.7254 5.30807 11.5935L0.439241 6.6984C0.303652 6.57138 0.194899 6.4182 0.119471 6.248C0.0440431 6.0778 0.00348419 5.89407 0.000214773 5.70777C-0.00305464 5.52147 0.0310325 5.33642 0.100442 5.16365C0.169851 4.99088 0.273161 4.83394 0.404209 4.70219C0.535257 4.57043 0.691357 4.46657 0.863198 4.39678C1.03504 4.327 1.2191 4.29273 1.4044 4.29602C1.5897 4.2993 1.77245 4.34008 1.94173 4.41591C2.11102 4.49175 2.26337 4.60109 2.38972 4.73741L6.24283 8.60944L12.6334 0.423223C12.6449 0.40898 12.6572 0.395392 12.6702 0.382523H12.6684Z" fill="#2BB483" />
                </svg>
                <div>Performed to industry standards</div>`,
      label: 'Pressure Test and Certify Assembly',
    }) : '',
  ];
  return `<html>
    <head>
        <style>
            ${rawPrintStyle}
        </style>
    </head>
    <body class="print">
        <div class="print-body">
            <h1>Your Assembly</h1>
            <p>Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable.</p>
            ${items.join('')}
        </div>
    </body>
</html>`;
};
