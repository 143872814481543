/**
 * WARNING: Do not rename, see config-override.js
 */

import rawPrintStyle from 'styles/common.scss';

/* eslint-disable-next-line */
let styleBundle = '';

if (Array.isArray(rawPrintStyle)) {
  styleBundle = rawPrintStyle.map((item) => {
    // eslint-disable-next-line
    const [name, content] = item;
    return content;
  }).join('\n\r');
}


export default styleBundle;
