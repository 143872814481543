import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from 'react';
import classNames from 'classnames';

import { WithChildren } from 'common/types';

import styles from './styles.module.scss';

export interface IRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string,
  label?: string,
  image?: string,
}

const RadioButton = forwardRef(({
  name,
  label,
  image,
  className,
  children,
  ...props
}: WithChildren<IRadioButtonProps>, ref: ForwardedRef<HTMLInputElement>) => (
  <div className={classNames(styles.wrapper, className)}>
    {image && (
      <img
        className={styles.image}
        src={image}
        alt={name}
      />
    )}
    <div className={styles.body}>
      <input
        {...props}
        ref={ref}
        type="radio"
        id={`${props.value}`}
        name={name}
        className={styles.input}
        tabIndex={0}
      />
      {!children && (
        <label
          htmlFor={`${props.value}`}
          className={classNames(styles.radioButton, className)}
        >
          {label}
        </label>
      )}
      {children}
    </div>
  </div>
));

// @ts-ignore
export default RadioButton;
