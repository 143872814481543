import HoseSelectorView from 'views/HoseSelectorView';
import AssemblyLengthView from 'views/AssemblyLengthView';
import FittingsSelectionView from 'views/FittingsSelectionView';
import AngleRotationView from 'views/AngleRotationView';
import AccessoriesSelectorView from 'views/AccessoriesSelectorView';

import { ProjectFields } from 'common/types/fields';
import type { IStepView } from './types';
import SummaryView from '../../views/SummaryView';
import {
  STEP_ACCESSORIES, STEP_ANGLE, STEP_ASS_LENGTH, STEP_FITTING, STEP_HOSE,
} from './stepNames';

export const VIEWS_LIST: Array<IStepView> = [{
  title: 'Select Hose',
  stepName: STEP_HOSE,
  component: HoseSelectorView,
  description: 'Please use the below drop-down options to select the hose specifications you are looking for.',
  requiredFields: [
    ProjectFields.hoseApplicationAttribute,
    ProjectFields.hoseMaterialConstruction,
    ProjectFields.hosePressure,
    ProjectFields.hoseInsideDiameter,
    ProjectFields.hoseProduct,
  ],
}, {
  title: 'Select Fittings',
  stepName: STEP_FITTING,
  component: FittingsSelectionView,
  description: 'Please use the below drop downs to select the end style and size you need.',
  requiredFields: [
    ProjectFields.fitting1Gender,
    ProjectFields.fitting1ConnectionType,
    ProjectFields.fitting1ConnectionSize,
    ProjectFields.fitting1Product,
    ProjectFields.fitting2Gender,
    ProjectFields.fitting2ConnectionType,
    ProjectFields.fitting2ConnectionSize,
    ProjectFields.fitting2Product,
    ProjectFields.fitting2SameAs1,
  ],
}, {
  title: 'Overall Assembly Length',
  stepName: STEP_ASS_LENGTH,
  component: AssemblyLengthView,
  description: 'Please enter the required finished assembly length in inches or feet. See below images for measurement guide.',
  requiredFields: [ProjectFields.assemblyLength],
}, {
  title: 'Angle of Rotation',
  stepName: STEP_ANGLE,
  component: AngleRotationView,
  description: `Please enter the angle of rotation between fitting&nbsp;1 and fitting&nbsp;2 using the image below as 
a&nbsp;guide.<br/> Fitting 1 will be 0 degrees.`,
  requiredFields: [ProjectFields.angleOfRotation],
}, {
  title: 'Accessories/Options',
  stepName: STEP_ACCESSORIES,
  component: AccessoriesSelectorView,
  description: 'Please select any optional accessories from the list of available.',
  requiredFields: [ProjectFields.accessories, ProjectFields.testingConfiguration, ProjectFields.customerSerialNumber],
}, {
  title: 'Confirm Your Assembly',
  stepName: 'Confirm Assembly',
  component: SummaryView,
  // eslint-disable-next-line
  description: 'Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable. To modify, click the item below.',
  requiredFields: [],
}];

export const DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION = 'There are no results that match your criteria. Please select other values.';
