import React from 'react';
import {
  Control,
  RegisterOptions,
  UseFormWatch,
} from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { UseFormGetFieldState, UseFormSetValue } from 'react-hook-form/dist/types/form';

import type { IServerItemOption } from 'common/types/fields';

import { IRadioButtonProps } from 'common/components/RadioButton';
import RadioButtonHF from 'common/components/FormHF/RadioButtonHF';
import ErrorMessage from 'common/components/ErrorMessage';
import { ICustomOptionProps } from 'common/components/FieldGenerator/types';
import { ChangeHandlerType, IFormValues } from '../types';

import styles from './styles.module.scss';


interface IRadioButtonsListProps<TKey extends string> extends IRadioButtonProps {
  name: TKey,
  optionsList: Array<IServerItemOption>,
  selectedList?: Array<IServerItemOption>,
  control: Control<Record<string, IFormValues[keyof IFormValues]>>,
  rules?: RegisterOptions<Record<string, any>>,
  watch: UseFormWatch<Record<string, any>>,
  setValue: UseFormSetValue<Record<string, any>>,
  getFieldState: UseFormGetFieldState<Record<string, IFormValues[keyof IFormValues]>>,
  onChangeHandler?: ChangeHandlerType,
  renderOptionLabelComponent?: () => (React.FC<ICustomOptionProps> | undefined),
}

const RadioButtonsList = <TKey extends string = string>({
  name,
  optionsList,
  control,
  rules,
  watch,
  setValue,
  selectedList,
  getFieldState,
  onChangeHandler,
  renderOptionLabelComponent,
  ...props
}: IRadioButtonsListProps<TKey>) => {
  const fieldsState = getFieldState(name);

  const onChangeHandlerWrapper = (fieldVal: string) => {
    if (onChangeHandler) {
      onChangeHandler(fieldVal);
    }
  };

  const RenderComponent = renderOptionLabelComponent && renderOptionLabelComponent();

  return (
    <>
      {optionsList.map((option) => (
        RenderComponent
          ? (
            <RadioButtonHF
              key={option.id}
              name={name}
              value={option.id}
              label={option.title}
              image={option.attributes?.image}
              control={control}
              onChangeHandler={onChangeHandlerWrapper}
              disabled={props.disabled}
              rules={rules}
              className={styles.item}
            >
              <RenderComponent option={option} />
            </RadioButtonHF>
          )
          : (
            <div key={option.id}>
              <RadioButtonHF
                name={name}
                value={option.id}
                label={option.title}
                control={control}
                onChangeHandler={onChangeHandlerWrapper}
                disabled={props.disabled}
                rules={rules}
                className={styles.item}
              />
            </div>
          )
      ))}
      {fieldsState.error && <ErrorMessage text={fieldsState.error as unknown as string} />}
    </>
  );
};

export default RadioButtonsList;
