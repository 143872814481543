import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ActionMeta, default as ReactSelect, SingleValue } from 'react-select';

import { ICustomOptionProps } from 'common/components/FieldGenerator/types';

import SelectOption from 'common/components/Select/components/SelectOption';
import MenuList from 'common/components/Select/components/MenuList';
import ErrorMessage from 'common/components/ErrorMessage';

import { OptionType } from './types';
import styles from './styles.module.scss';

export interface ISelectProps {
  label?: string,
  options: Array<OptionType>
  error?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  isSearchable?: boolean,
  className?: string,
  value: OptionType,
  name: string,
  onChange?: (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void,
  renderOptionLabelComponent?: () => (React.FC<ICustomOptionProps> | undefined),
}
const FormattedLabel = (renderOptionLabelComponent: ISelectProps['renderOptionLabelComponent']) => (option: OptionType) => {
  let RenderComponent = renderOptionLabelComponent && renderOptionLabelComponent();
  if (!RenderComponent) {
    RenderComponent = SelectOption;
  }
  return (
    <RenderComponent option={option} />
  );
};

const Select = ({
  label,
  options,
  error,
  required,
  disabled,
  isSearchable = false,
  className,
  placeholder = '',
  value,
  onChange,
  renderOptionLabelComponent,
  name,
}: ISelectProps) => {
  useEffect(() => {
    if (options.length === 1 && !value && !disabled) {
      const v: OptionType = options[0]!;
      const meta: ActionMeta<OptionType> = {
        action: 'select-option',
        option: v,
        name,
      };
      const timeout = setTimeout(() => {
        if (onChange) {
          onChange(v, meta);
        }
      });

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [value, options, disabled]);
  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.disabled]: disabled,
      })}
    >
      {label && (
        <div className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </div>
      )}
      <ReactSelect
        name={name}
        className={classNames(styles.select, {
          [styles.error]: error,
        })}
        captureMenuScroll={false}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={isSearchable}
        classNamePrefix="select"
        components={{ MenuList }}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        options={options}
        value={value}
        onChange={onChange}
        formatOptionLabel={FormattedLabel(renderOptionLabelComponent)}
      />
      {error && <ErrorMessage text={error} />}
    </div>
  );
};

// @ts-ignore
export default Select;
