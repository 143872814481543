import type { PredefinedContextType } from 'containers/GenericFieldsHandlingLayer/context';
import { FieldsType } from 'common/types/fields';
import { IFormValues } from 'common/components/FormHF/types';

export const generateFieldNameByGroup = (baseName: string, subName: string) => `${baseName}_${subName}`;

export const prepareDefaultFormData = (fields: PredefinedContextType['data']['fields']): IFormValues => {
  const form: IFormValues = {};
  Object.entries(fields).forEach(([fieldName, fieldVal]) => {
    if (fieldVal) {
      switch (fieldVal.type) {
        case FieldsType.input: {
          form[fieldName] = fieldVal.value?.selected?.value;
          break;
        }
        case FieldsType.dropdown: {
          form[fieldName] = fieldVal?.value?.selected && fieldVal?.value?.selected[0];
          break;
        }
        case FieldsType.checkboxes: {
          fieldVal.value?.selected
            ?.forEach((option) => {
              form[generateFieldNameByGroup(fieldName, option.id)] = option.id;
            });
          break;
        }
        case FieldsType.radioGroup: {
          form[fieldName] = fieldVal?.value?.selected && fieldVal.value?.selected[0].id;
          break;
        }
        default: break;
      }
    }
  });
  return form;
};
