import { ConfiguredRecordType, ISchemaDataStorage } from 'containers/GenericFieldsHandlingLayer/types';
import { ProjectFields } from 'common/types/fields';
import { GeneratorFieldType } from 'common/components/FieldGenerator/types';
import { ExtendedDataStorageType } from 'containers/AdditionalDataStorage/types';
import { LengthType } from 'common/types/additional';
import { toFixedByLengthType } from 'views/AssemblyLengthView/utils';
import {
  addItem, generateConfigId, getSingleNumberValue, getSingleStringValue, isCPQData, wrapData,
} from './utils';
import { ICPQCart, ICPQItem } from './types';


export const mapProjectFieldsToCPQCart = (
  fields: ConfiguredRecordType<ProjectFields, GeneratorFieldType>,
  additional: ExtendedDataStorageType,
): ICPQCart => {
  const items: ICPQItem[] = [];
  const requiredComponents: ICPQItem[] = [];
  const accessories: ICPQItem[] = [];

  addItem(items, 'hose', fields.hoseProduct?.value?.selected);
  addItem(items, 'fitting1', fields.fitting1Product?.value?.selected);
  addItem(items, 'fitting2', fields.fitting2Product?.value?.selected);

  const customerPartNumber = getSingleStringValue(fields.customerSerialNumber?.value?.selected)!;

  addItem(requiredComponents, 'required', fields.requiredComponents?.value?.selected);

  addItem(accessories, 'main', fields.accessories?.value?.selected);

  if (fields.testingConfiguration?.value?.selected) {
    addItem(accessories, 'testing', fields.testingConfiguration?.value?.selected);
  }

  const lengthInInches = getSingleNumberValue(fields.assemblyLength?.value?.selected)!;
  const lengthInFeet = toFixedByLengthType(lengthInInches, LengthType.inches, LengthType.feet);

  const assembly = {
    angleOfRotation: getSingleNumberValue(fields.angleOfRotation?.value?.selected)!,
    assemblyLength_feet: lengthInFeet,
    assemblyLength_inches: lengthInInches,
    length_type: additional.lengthType,
  };

  return {
    generatedConfigId: generateConfigId([...items, ...accessories, ...requiredComponents], assembly, customerPartNumber),
    quantity: additional.configQuantity,
    items,
    requiredComponents,
    accessories,
    customerPartNumber,
    assemblyProperties: assembly,
  };
};

export const sendDataToParentIframe = async (data: ISchemaDataStorage['fields'], additional: ExtendedDataStorageType) => {
  if (window.self !== window.parent) {
    return new Promise<void>((resolve, reject) => {
      window.parent.postMessage(wrapData(mapProjectFieldsToCPQCart(data, additional)), '*');

      const listenToResponse = (e: MessageEvent) => {
        if (isCPQData(e.data)) {
          const { error, isLoading } = e.data;

          if (!isLoading) {
            if (!error) {
              resolve();
            } else {
              reject(new Error(error));
            }

            window.removeEventListener('message', listenToResponse);
          }
        }
      };

      window.addEventListener('message', listenToResponse);
    });
  }
  throw new Error('Can only send data to parent iframe being in iframe');
};
