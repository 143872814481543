import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from 'react';
import classNames from 'classnames';

import ErrorMessage from 'common/components/ErrorMessage';

import styles from './styles.module.scss';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  error?: string,
  required?: boolean,
}

const Input = forwardRef(({
  name,
  label,
  error,
  required,
  disabled,
  className,
  ...props
}: IInputProps, ref: ForwardedRef<HTMLInputElement>) => (
  <div
    className={classNames(styles.wrapper, className, {
      [styles.disabled]: disabled,
    })}
  >
    {label && (
      <div className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </div>
    )}
    <input
      {...props}
      ref={ref}
      className={classNames(styles.input, {
        [styles.error]: error,
      })}
      name={name}
      disabled={disabled}
    />
    {error && <ErrorMessage text={error} />}
  </div>
));

export default Input;
