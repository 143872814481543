import React, { useCallback } from 'react';

import { Control, UseFormWatch } from 'react-hook-form';
import { IFormValues } from 'common/components/FormHF/types';
import CheckboxHF from 'common/components/FormHF/CheckboxHF';
import type { UseFormSetValue } from 'react-hook-form/dist/types/form';
import styles from './styles.module.scss';


interface IProps<TKey extends string> {
    className?: string,
    control: Control<Record<string, IFormValues[keyof IFormValues]>>
    setValue: UseFormSetValue<Record<string, any>>,
    watch: UseFormWatch<Record<TKey, any>>,
}

// eslint-disable-next-line
const text = 'Using these products involves risks; ensure proper safety precautions for your application. Improper use may result in damage, injury, or death. Verify component specifications before purchasing. Custom assemblies are non-returnable and non-refundable. By checking this box, you acknowledge and accept these terms.';

export const TosCheckboxConnected = <TKey extends string = string>({
  watch, className, control, setValue,
}: IProps<TKey>) => {
  const value = watch('tosAgreed' as any);

  const handleSetCounter = useCallback((fieldName: string) => {
    setValue(fieldName, !value);
  }, [setValue, value]);

  return (
    <CheckboxHF
      control={control}
      name="tosAgreed"
      className={className}
      value="tosAgreed"
      onChangeHandler={handleSetCounter}
      description={text}
    >
      Accept
      <span className={styles.required}>*</span>
    </CheckboxHF>
  );
};

export default TosCheckboxConnected;
