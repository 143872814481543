export const scrollToEmptyInput = () => {
  let maxTop = 10000;
  let element: Element | null = null;
  document.querySelectorAll('[data-field-wrapper][data-has-value="0"][data-ignore-scroll-to="0"],[data-submit-button]').forEach((el) => {
    if (el.getBoundingClientRect().top < maxTop && el.getBoundingClientRect().height) {
      maxTop = el.getBoundingClientRect().top;
      element = el;
    }
  });
  if (element) {
    (element as Element).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};
