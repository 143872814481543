import { ValidationDetailsType, ValidationTypes } from 'common/types/fields';
import { isMaxValidationType, isMinValidationType } from 'utils/isMinMaxValidation';

export const getValidationErrorMessage = (validation: ValidationDetailsType, value?: number | string) => {
  if (validation.promt) {
    if (validation.promt.includes('{{')) {
      const updatedPromt = validation.promt.replaceAll(/{{(\w*)}}/gi, (match, propsReplaceName: string) => {
        if (!propsReplaceName || !(validation as Record<string, any>)[propsReplaceName]) {
          switch (validation.type) {
            case ValidationTypes.pattern:
              return validation.pattern;
            case ValidationTypes.range:
              if (isMaxValidationType(validation)) {
                return String(validation.maxValue);
              } if (isMinValidationType(validation)) {
                return String(validation.minValue);
              }
              return '';

            default:
              return '';
          }
        }
        return (validation as Record<string, any>)[propsReplaceName];
      });

      return updatedPromt;
    }
    return validation.promt;
  }
  switch (validation.type) {
    case ValidationTypes.required:
      return 'Field is required';
    case ValidationTypes.pattern:
      return `Field does not match the pattern ${value}`;
    case ValidationTypes.range:
      if (isMaxValidationType(validation)) {
        return `Value should be less then ${value}`;
      } if (isMinValidationType(validation)) {
        return `Value should be greater then ${value}`;
      }
      return '';

    default:
      return '';
  }
};
