import React from 'react';
import { components } from 'react-select';

import Scrollbar from 'common/components/Scrollbar';

const MenuList = ({ children, ...props }: any) => (
  <components.MenuList {...props}>
    <Scrollbar
      autoHeight
      autoHeightMin={0}
      autoHeightMax={210}
    >
      {children}
    </Scrollbar>
  </components.MenuList>
);

// @ts-ignore
export default MenuList;
