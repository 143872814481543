import {
  IRangeMinValidationDetails,
  IRangeMaxValidationDetails,
  ValidationDetailsType,
  ValidationTypes,
  ILengthMinValidationDetails,
  ILengthMaxValidationDetails,
} from 'common/types/fields';

export const isMinValidationType = (value: ValidationDetailsType): value is IRangeMinValidationDetails => (
  value.type === ValidationTypes.range && (value as IRangeMinValidationDetails).minValue !== undefined
);

export const isMaxValidationType = (value: ValidationDetailsType): value is IRangeMaxValidationDetails => (
  value.type === ValidationTypes.range && (value as IRangeMaxValidationDetails).maxValue !== undefined
);

export const isMinLengthValidationType = (value: ValidationDetailsType): value is ILengthMinValidationDetails => (
  value.type === ValidationTypes.length && (value as ILengthMinValidationDetails).minValue !== undefined
);

export const isMaxLengthValidationType = (value: ValidationDetailsType): value is ILengthMaxValidationDetails => (
  value.type === ValidationTypes.length && (value as ILengthMaxValidationDetails).maxValue !== undefined
);

