import { IServerItemOption, IServerTextOption } from 'common/types/fields';
import { LengthType } from 'common/types/additional';
import { IFRAME_MESSAGE_TYPE } from '../constants';
import {
  ICPQCart, ICPQItem, ICPQResponse, ICPQType,
} from './types';

export const wrapData = (data: unknown) => ({
  type: IFRAME_MESSAGE_TYPE,
  payload: data,
});
export const isCPQData = (data: unknown): data is ICPQResponse => (data as ICPQResponse)?.type === IFRAME_MESSAGE_TYPE;
export const addItem = (array: ICPQItem[], type: ICPQType, options: IServerTextOption | IServerItemOption[] | undefined) => {
  if (options && Array.isArray(options)) {
    options.forEach((option) => {
      array.push({
        type,
        quantity: 1,
        sku: option.id,
        image: option.attributes?.image,
        description: option.attributes?.description,
        title: option.title,
      });
    });
  }
};
export const getSingleStringValue = (options: IServerTextOption | IServerItemOption[] | undefined) => {
  if (options && Array.isArray(options) && options.length === 1) {
    return options[0].id;
  }
  if (options && !Array.isArray(options)) {
    return String(options.value);
  }
  return undefined;
};

export const getSingleOption = (options: IServerTextOption | IServerItemOption[] | undefined) => {
  if (options && Array.isArray(options) && options.length === 1) {
    return options[0];
  }
  return undefined;
};

export const getSingleNumberValue = (options: IServerTextOption | IServerItemOption[] | undefined) => {
  const str = getSingleStringValue(options);
  if (str) {
    return Number(str);
  }
  return undefined;
};
export const generateConfigId = (items: ICPQItem[], config: ICPQCart['assemblyProperties'], suffix: string) => {
  const part1 = items.map((i) => `${i.type}_${i.sku}`).join(',');
  const part2 = `${config.angleOfRotation}deg,${
    (config.length_type === LengthType.inches) ? config.assemblyLength_inches : config.assemblyLength_feet
  }${config.length_type}`;
  return `${part1}:${part2} [${suffix}]`;
};
