export const PRODUCTION_ENV_NAME = 'production';
export const DEV_ENV_NAME = 'development';

export const SERVER_ADDRESS = process.env.REACT_APP_API_ADDRESS;
export const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const RULE_SET_UUID = process.env.REACT_APP_RULE_SET_UUID;
export const ENV = process.env.NODE_ENV;
export const IS_DEV = process.env.NODE_ENV === PRODUCTION_ENV_NAME;

export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL || 'customercare@alaskarubbergroup.com';
