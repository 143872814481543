import { API_PREFIX, RULE_SET_UUID } from '../common/constants';

export const API_ENDPOINTS = {
  getWholeSchema: (sessionId?: string, params?: Record<string, string>) => {
    const allParams = {
      sessionId,
      ruleSetUuid: RULE_SET_UUID,
      ...params,
    };

    // eslint-disable-next-line no-unused-vars
    const suffix = Object.entries(allParams).filter(([k, v]) => !!v).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v!)}`).join('&');

    return `${API_PREFIX}/rules-engine/schema${suffix ? `?${suffix}` : ''}`;
  },
  // eslint-disable-next-line no-unused-vars
  updateFieldsState: (sessionId: string) => `${API_PREFIX}/rules-engine/apply`,
  printPDF: () => `${API_PREFIX}/summary/generate`,
};
