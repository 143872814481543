import { IFormValues } from 'common/components/FormHF/types';
import {
  CheckboxFieldStateType,
  RadioFieldStateType,
  DropdownFieldStateType,
  FieldsType,
  ProjectFields, InputFieldStateType,
} from 'common/types/fields';

import { OptionType } from 'common/components/Select/types';
import { IToFormAdapters, IToServiceAdapters, RecordUnionValueType } from './types';
import { ISchemaDataStorage, ISchemaDataStorageFields } from '../GenericFieldsHandlingLayer/types';


export const getUpdatedFieldToForm = <TField>(field: RecordUnionValueType<ISchemaDataStorage['fields']>, toForm: IToFormAdapters<TField>) => {
  if (!field) {
    return undefined;
  }
  switch (field.type) {
    case FieldsType.input: {
      return toForm.inputToFormAdapter!(field);
    }
    case FieldsType.dropdown: {
      return toForm.dropdownToFormAdapter!(field);
    }
    case FieldsType.checkboxes: {
      return toForm.checkboxToFormAdapter!(field);
    }
    case FieldsType.radioGroup: {
      return toForm.radioToFormAdapter!(field);
    }
    default:
      return undefined;
  }
};

export const getUpdatedFieldToService = (
  fieldType: FieldsType,
  fieldName: ProjectFields,
  storedField: RecordUnionValueType<ISchemaDataStorageFields>,
  toService: IToServiceAdapters,
  fieldVal?: IFormValues[keyof IFormValues],
) => {
  switch (fieldType) {
    case FieldsType.input: {
      return toService.inputToServiceAdapter!(
        fieldName,
        fieldVal as unknown as string,
      );
    }
    case FieldsType.dropdown: {
      return toService.dropdownToServiceAdapter!(
        fieldName,
        (storedField as DropdownFieldStateType).value!.options,
        fieldVal as unknown as OptionType,
      );
    }
    case FieldsType.checkboxes: {
      return toService.checkboxToServiceAdapter!(
        fieldName,
        (storedField as CheckboxFieldStateType).value!.options,
        fieldVal as unknown as Array<string>,
      );
    }
    case FieldsType.radioGroup: {
      return toService.radioToServiceAdapter!(
        fieldName,
        (storedField as RadioFieldStateType).value!.options,
        fieldVal as unknown as string,
      );
    }
    default:
      return undefined;
  }
};

export const getStoredFieldToService = (
  storedField: RecordUnionValueType<ISchemaDataStorageFields>,
  toService: IToServiceAdapters,
) => {
  if (!storedField) {
    return undefined;
  }
  switch (storedField!.type) {
    case FieldsType.input: {
      return toService.inputToServiceAdapter!(
        storedField.name,
        (storedField as InputFieldStateType).value?.selected?.value,
      );
    }
    case FieldsType.dropdown: {
      const selected = (storedField as DropdownFieldStateType).value?.selected; // TODO: backend returns not an array atm
      return toService.dropdownToServiceAdapter!(
        storedField.name,
        (storedField as DropdownFieldStateType).value?.options,
        (selected && selected.length) ? selected[0] : undefined,
      );
    }
    case FieldsType.checkboxes: {
      const selected = (storedField as CheckboxFieldStateType).value?.selected;
      return toService.checkboxToServiceAdapter!(
        storedField.name,
        (storedField as CheckboxFieldStateType).value?.options,
        selected ? selected.map((s) => s.id) : undefined,
      );
    }
    case FieldsType.radioGroup: {
      const selected = (storedField as RadioFieldStateType).value?.selected;
      return toService.radioToServiceAdapter!(
        storedField.name,
        (storedField as RadioFieldStateType).value?.options,
        selected ? selected[0].id : undefined,
      );
    }
    default:
      return undefined;
  }
};
