import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';

import { IS_DEV, SERVER_ADDRESS } from 'common/constants';
import { makeDefaultError } from 'utils/makeDefaultError';

import { IAxiosResponseError } from './types';
import { CONNECTION_TYPES, DEFAULT_SERVER_ERROR_MESSAGE } from './constants';


export const CONTENT_TYPE: string = 'application/json';

const onResponseSuccess = (response: AxiosResponse) => response;

const onResponseError = async (error: any): Promise<IAxiosResponseError<any>> => {
  // const originalRequest = error.config;
  const standardError: IAxiosResponseError<any> = makeDefaultError(error);

  if (error.response) {
    standardError.status = error.response.status;
    standardError.data = error.response.data;
    if (error.response.status >= 500 && error.response.status < 600) {
      // hide from user internal errors messages as not needed info
      standardError.statusText = DEFAULT_SERVER_ERROR_MESSAGE; // error.response.statusText;
    }
  } else if (error.request) {
    if (error.status) {
      standardError.status = error.status;
    }
    if (error.message?.contains('Network Error')) {
      standardError.status = 599; // network timed out
      standardError.statusText = 'Network Error: Timed Out';
      if (typeof navigator.onLine !== 'undefined' && !navigator.onLine) {
        standardError.data = {
          message: 'Device seems to be offline, check your network connection',
        };
      } else {
        standardError.data = {
          message: 'Please check your network connection',
        };
      }
    }
  }

  return Promise.reject(standardError);
};

const axiosInstanceGenerator = (xAPIToken?: string, overrideHeaders: AxiosRequestHeaders = {}) => {
  const headers: AxiosRequestHeaders = {
    'Content-Type': CONTENT_TYPE,
    Accept: CONTENT_TYPE,
    ...overrideHeaders,
  };
  if (xAPIToken) {
    headers['X-API-Key'] = xAPIToken;
  }

  const preparedInstance = axios.create({
    baseURL: IS_DEV ? SERVER_ADDRESS : undefined,
    headers,
  });

  // here the place to add retry policy

  preparedInstance.interceptors.response.use(
    onResponseSuccess,
    onResponseError,
  );
  return preparedInstance;
};

const defaultAxiosInstance = axiosInstanceGenerator();

export const requestHandlerFabric = (
  connectionType: CONNECTION_TYPES = CONNECTION_TYPES.AXIOS,
  xAPIToken?: string,
  overrideHeaders?: AxiosRequestHeaders,
) => {
  switch (connectionType) {
    case CONNECTION_TYPES.AXIOS: {
      let axiosPreparedInstance = defaultAxiosInstance;
      if (xAPIToken) {
        axiosPreparedInstance = axiosInstanceGenerator(xAPIToken, overrideHeaders);
      }
      return axiosPreparedInstance;
    }
    default:
      return defaultAxiosInstance;
  }
};
