import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IErrorMessageProps {
  text: string,
  isLeft?: boolean,
  className?: string
}

const ErrorMessage = ({
  text,
  isLeft,
  className,
}: IErrorMessageProps) => (
  <div className={classNames(styles.error, className, { [styles.alignLeft]: isLeft })}>{text}</div>
);

export default ErrorMessage;
