import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from 'react';
import classNames from 'classnames';

import { WithChildren } from 'common/types';

import styles from './styles.module.scss';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string,
  label?: string,
  description?: string,
  disabled?: boolean,
  error?: string | undefined,
  image?: string,
}

const Checkbox = forwardRef(({
  name,
  label,
  image,
  className,
  description,
  disabled,
  children,
  error,
  ...props
}: WithChildren<ICheckboxProps>, ref: ForwardedRef<HTMLInputElement>) => (
  <div className={styles.wrapper}>
    {image && (
      <img
        className={styles.image}
        src={image}
        alt={name}
      />
    )}
    <div className={styles.body}>
      <input
        {...props}
        ref={ref}
        type="checkbox"
        id={name}
        className={classNames(styles.input)}
        tabIndex={0}
      />
      <label
        htmlFor={name}
        className={classNames(styles.checkbox, className, {
          [styles.error]: error,
          [styles.disabled]: disabled,
        })}
      >
        {label || children}
      </label>

      {description && <p className={styles.description}>{description}</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  </div>
));

// @ts-ignore
export default Checkbox;
