import Cookies from 'js-cookie';

export const recoverSessionId = () => {
  const reset = '#new';
  if (window.location.hash === reset) {
    const url = window.location.href;
    window.location.replace(url.substring(0, url.length - reset.length));
    Cookies.remove('sessionId');
    return undefined;
  }
  return Cookies.get('sessionId');
};

export default recoverSessionId;
