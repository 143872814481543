import React, { useMemo } from 'react';

import { ProjectFields } from 'common/types/fields';
import { SelectedConfiguration } from 'common/types/additional';
import { CardStatus } from 'common/components/CardNav/constants';
import { useActiveSelectedConfiguration } from 'containers/AdditionalDataStorage/context';
import { useGenericFieldsActionsAdopted } from 'containers/FormDataAdapter';

import SecondaryNav from 'common/components/SecondaryNav';

import emptyImageHose from 'assets/empty-image-hose.svg';
import emptyImageFitting1 from 'assets/empty-image-fitting1.svg';
import emptyImageFitting2 from 'assets/empty-image-fitting2.svg';
import { OptionType } from '../Select/types';

const SecondaryNavWrapper = () => {
  const { getFieldsByName } = useGenericFieldsActionsAdopted();
  const fields = useMemo(() => getFieldsByName([ProjectFields.hoseProduct, ProjectFields.fitting1Product, ProjectFields.fitting2Product]), [getFieldsByName]);
  const activeSelectedConfiguration = useActiveSelectedConfiguration();

  const hoseSecondaryNav = useMemo(() => {
    const hoseSelected = fields[ProjectFields.hoseProduct]?.value?.selected as OptionType[] | undefined;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.hose,
      status: hoseSelected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImageHose,
      emptyBodyLabel: !hoseSelected ? 'Hose' : undefined,
      selectedItem: hoseSelected ? {
        image: hoseSelected[0].attributes?.image!,
        title: hoseSelected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, fields[ProjectFields.hoseProduct]?.value?.selected]);

  const fitting1SecondaryNav = useMemo(() => {
    const fitting1Selected = fields[ProjectFields.fitting1Product]?.value?.selected as OptionType[] | undefined;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.fitting1,
      status: fitting1Selected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImageFitting1,
      emptyBodyLabel: !fitting1Selected ? 'Fitting 1' : undefined,
      selectedItem: fitting1Selected ? {
        image: fitting1Selected[0].attributes?.image!,
        title: fitting1Selected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, fields.fitting1Product]);

  const fitting2SecondaryNav = useMemo(() => {
    const fitting2Selected = fields[ProjectFields.fitting2Product]?.value?.selected as OptionType[] | undefined;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.fitting2,
      status: fitting2Selected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImageFitting2,
      emptyBodyLabel: !fitting2Selected ? 'Fitting 2' : undefined,
      selectedItem: fitting2Selected ? {
        image: fitting2Selected[0].attributes?.image!,
        title: fitting2Selected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, fields.fitting2Product]);

  return (
    <SecondaryNav
      fitting1={fitting1SecondaryNav}
      fitting2={fitting2SecondaryNav}
      hose={hoseSecondaryNav}
    />
  );
};

export default SecondaryNavWrapper;
