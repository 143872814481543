// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';
import type { ICustomDescriptionProps, ICustomOptionProps } from 'common/components/FieldGenerator/types';

import { FieldsType, ProjectFields } from 'common/types/fields';
import { SelectedConfiguration } from 'common/types/additional';
import { useActions } from 'containers/AdditionalDataStorage/context';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { FormKeyType, IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import { FormLoader } from 'common/components/Loader';

import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';
import HoseProductOption from './components/HoseProductOption';
import { isFieldDisabled } from '../../utils/isStatusDisabled';
import HoseProductDescription from './components/HoseProductDescription';


const HoseSelectorView = ({
  onNextClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  isNextDisabled,
  children,
  clearProgressError,
  handleSetNoFieldsAvailable,
}: WithChildren<IViewProps>) => {
  const idForm = 'form-hose-selector';

  const { error, isLoading, order } = useGenericFieldsDataAdopted();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted();
  const { setData } = useActions();

  useEffect(() => {
    setData('activeSelectedConfiguration', SelectedConfiguration.hose);

    return () => setData('activeSelectedConfiguration', null);
  }, [setData]);

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
  } = useForm<IFormValues>({ defaultValues });

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const renderHoseOptionComponent = useCallback<(
    fieldId: ProjectFields) => React.FC<ICustomOptionProps> | undefined>(
    (fieldId: ProjectFields) => {
      switch (fieldId) {
        case ProjectFields.hoseProduct: {
          return HoseProductOption;
        }
        default:
          return undefined;
      }
    },
    [],
    );

  const renderDescriptionComponent = useCallback<(
    fieldId: ProjectFields) => React.FC<ICustomDescriptionProps> | undefined>(
    (fieldId: ProjectFields) => {
      switch (fieldId) {
        case ProjectFields.hoseProduct: {
          return HoseProductDescription;
        }
        default:
          return undefined;
      }
    },
    [],
    );

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: ProjectFields,
    changedValue?: IFormValues[keyof IFormValues],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {isLoading && <FormLoader />}
        {order && order.map((fieldName) => {
          if (fieldsNames.includes(fieldName) && !!fields[fieldName]) {
            return (
              <FieldGenerator<FormKeyType>
                field={fields[fieldName]!}
                name={fields[fieldName]!.name}
                control={control}
                watch={watch}
                setValue={setValue}
                getFieldState={getFieldState}
                key={fields[fieldName]!.name}
                onChangeHandler={handleFieldUpdate}
                isDisabled={isFieldDisabled(fields[fieldName])}
                resetField={resetField}
                renderDescriptionComponent={renderDescriptionComponent}
                renderOptionLabelComponent={renderHoseOptionComponent}
              />
            );
          }
          return null;
        })}
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        isNextDisabled={isNextDisabled || !!error}
        isBackHidden
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default HoseSelectorView;
