import React from 'react';
import classNames from 'classnames';

import { ICard } from './types';
import { CardStatus } from './constants';

import EmptyBody from './components/EmptyBody';
import FulfilledBody from './components/FulfilledBody';

import styles from './styles.module.scss';

const CardNav = ({
  active = false,
  defaultImage,
  status = CardStatus.empty,
  selectedItem,
  emptyBodyLabel,
  className,
}: ICard) => (
  <div
    className={classNames(styles.card, className, {
      [styles.active]: active,
    })}
  >
    {status === CardStatus.fulfilled && selectedItem
      ? (
        <FulfilledBody
          selectedItem={selectedItem}
          status={status}
          defaultImage={defaultImage}
        />
      )
      : emptyBodyLabel && (
        <EmptyBody
          emptyBodyLabel={emptyBodyLabel}
          defaultImage={defaultImage}
        />
      )}
  </div>
);

export default CardNav;
