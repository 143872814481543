export enum ButtonSchema {
  primary = 'primary',
  secondary = 'secondary',
  transparent = 'transparent',
}

export enum ButtonSize {
  small = 'small',
  standard = 'standard',
  full = 'full',
}
